const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDateString(dateString) {
  const date = new Date(dateString);

  // Extract year, month, day, hours, and minutes
  const year = date.getFullYear();
  const monthIndex = date.getMonth(); // Get the month index
  const month = months[monthIndex]; // Get the month name
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format the date and time
  return `${day}-${month}-${year} at ${hours}:${minutes}`;
}
