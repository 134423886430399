import * as types from "./action.types";
import axios from "axios";
import { setToast } from "../../../../Fucntions/Toastfunction";

export const getUpdatedOrderAfterAssignRejectedOrder=(data)=>(dispatch)=>{
  dispatch({type:types.GET_ORDER_DATA_LOADING})
  try{
    dispatch({type:types.GET_ORDER_DATA_SUCCESS,payload:data})
  }
  catch{
    dispatch({ type: types.GET_ORDER_DATA_ERROR });
  }
}

export const getOrderData=(url,toast,navigate)=>async(dispatch)=>{
  // console.log("url",url)
dispatch({type:types.GET_ORDER_DATA_LOADING})
const headers = {
  "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
};
return axios({
  method: "GET",
  withCredentials: false,
  url: url,
  headers: headers,
})
  .then((r)=>{
   
    dispatch({type:types.GET_ORDER_DATA_SUCCESS,payload:r.data})
  })
  .catch((err)=>{
   
    if(err.response.data.err==="Invalid token"){
      sessionStorage.removeItem('admintoken');
      navigate("/admin/login")
      return
    }
    // console.log(err,"err")
    dispatch({ type: types.GET_ORDER_DATA_ERROR });
    // setToast(toast, `${err.response.data.err}`, "", "error");
  })
}

export const editOrderData=(data)=>async(dispatch)=>{
      dispatch({type:types.GET_ORDER_DATA_SUCCESS,payload:data})
  }

export const getFilteredSubOrderData=(url,toast,navigate,data)=>async(dispatch)=>{
  
  dispatch({type:types.GET_ORDER_DATA_LOADING})
  const headers = {  
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method: "post",
    withCredentials: false,
    url: url,
    headers: headers,
    data:data
  })
    .then((r)=>{
       
      dispatch({type:types.GET_ORDER_DATA_SUCCESS,payload:r.data})
    })
    .catch((err)=>{
      if(err.response.data.err==="Invalid token"){
        sessionStorage.removeItem('admintoken');
        navigate("/admin/login")
        return
      }
      dispatch({ type: types.GET_ORDER_DATA_ERROR });
      setToast(toast, `${err.response.data.err}`, "", "error");
    })
  }

export const UpdateSuborderData=(url,data,toast,update,navigate)=>async(dispatch)=>{
  // console.log(url,"url")
  dispatch({type:types.UPDATE_SUBORDER_DATA_LOADING})
  const headers = {
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method:"PUT",
    data:data,
    url:url,
    headers
  }).then((r)=>{
    dispatch({type:types.UPDATE_SUBORDER_DATA_SUCCESS,payload:r.data})
    setToast(toast,`${update} updated successfully`,"","success","top")
  })
  .catch((err)=>{
    if(err.response.data.err==="Invalid token"){
      sessionStorage.removeItem('admintoken');
      navigate("/admin/login")
      return
    }
    dispatch({type:types.UPDATE_SUBORDER_DATA_ERROR})
  })


}

export const OrderEditingBegin=(url,navigate)=>async(dispatch)=>{
  // console.log("clled")
  dispatch({type:types.ORDER_EDITING_START_LOADING})
  const headers = {
    "Authorization":`Bearer ${JSON.parse(sessionStorage.getItem("admintoken"))}`
  };
  return axios({
    method:"GET",url, headers
  }).then((r)=>{
    dispatch({type:types.ORDER_EDITING_START_SUCCESS,payload:r.data    })
  })
  .catch((err)=>{
    if(err.response.data.err==="Invalid token"){
      sessionStorage.removeItem('admintoken');
      navigate("/admin/login")
      return
    }
    dispatch({type:types.ORDER_EDITING_START_ERROR})
  })
}

export const AddProductAfterStartOrderEditing=(data)=>(dispatch)=>{
  dispatch({type:types.UPDATE_SUBORDER_DATA_LOADING})
  try{
    dispatch({type:types.UPDATE_SUBORDER_DATA_SUCCESS,payload:data})
    
  }
  catch{
    dispatch({type:types.UPDATE_SUBORDER_DATA_ERROR})
  }
}

export const OrderAfterStartOrderEditing=(data)=>(dispatch)=>{
  dispatch({type:types.ORDER_EDITING_START_LOADING})
  try{
    dispatch({type:types.ORDER_EDITING_START_SUCCESS,payload:data})
    
  }
  catch{
    dispatch({type:types.ORDER_EDITING_START_ERROR})
  }
}

export const DeliverySlotsAfterHittingApi=(data)=>(dispatch)=>{
  try{
    dispatch({type:types.ORDER_EDITING_DELIVERY_SLOT_SUCCESS,payload:data})
  }
  catch{
    dispatch({type:types.ORDER_EDITING_DELIVERY_SLOT_ERROR})
  }
}

export const OrderEditingAddProductAndVarinatId=(data)=>(dispatch)=>{
  dispatch({type:types.ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_LOADING})

    dispatch({type:types.ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_SUCCESS,payload:data})

    dispatch({type:types.ORDER_EDITING_PRODUCT_AND_VARIANTID_ADDED_ERROR})
  
}

export const OrderEditingNoteattr=(data)=>(dispatch)=>{
    dispatch({type:types.ORDER_EDITING_ORDER_NOTE_ATTR_SUCCESS,payload:data})
}

export const SetSelectedTimeSLot=(data)=>(dispatch)=>{
  // console.log(data,"data")
  dispatch({type:types.SET_SELECTED_TIME_SLOT,payload:data})
}

export const OrderEditingDateAndCityDeail=(data)=>(dispatch)=>{

  try{
    dispatch({type:types.ORDER_EDITING_DATE_CITY_DATA_SUCCESS,payload:data})
  }
  catch{
    dispatch({type:types.ORDER_EDITING_DATE_CITY_DATA_ERROR})
  }
}

// export const Creat_ORDERMethodDetails=(url,data,toast,newurl,navigate,onClose,set)=>async(dispatch)=>{
//   dispatch({type:types.CREATE_ORDER_DATA_LOADING})
//   const headers = {

//   };
//   return axios({
//     method: "POST",
//     url: url,
//     headers: headers,
//     data:data
//   })
//   .then((r)=>{
    
//     dispatch({type:types.CREATE_ORDER_DATA_SUCCESS})
//     setToast(toast, _ORDER Method Created Successfully", "", "success", "top");
//     dispatch(ge_ORDERMethodsData(newurl))
//     navigate(`_ORDERmethods`)
//     set({
//       name:'',
//       message:'',
//       price:'',
//       warning_message:'',
//       time_slots:[]
//     })
//     onClose()
//   })
//   .catch((e)=>{
//     setToast(toast, `${e.response.data.err}`, "", "error", "top");
//     dispatch({type:types.CREATE_ORDER_DATA_ERROR})
//   })
// }

// export const Delet_ORDERMethodDetails=(url,data,toast,newurl,close)=>async(dispatch)=>{

//   dispatch({type:types.DELETE_ORDER_DATA_LOADING})
//   let name={
//     "name":data
//   }
//   return axios({
//     method: "DELETE",
//     url: url,
//     data:name
//   })
//   .then((r)=>{
//     dispatch({type:types.DELETE_ORDER_DATA_SUCCESS})
//     setToast(toast, _ORDER Method Deleted ", "", "success", "top");
//     dispatch(ge_ORDERMethodsData(newurl))
//     close()
//   })
//   .catch((e)=>{
//     setToast(toast, `${e.response.data.err}`, "", "error", "top");
//     dispatch({type:types.DELETE_ORDER_DATA_ERROR})
//   })
// }

export const CallPrintChallanofOrder=(data)=>(dispatch)=>{
  dispatch({type:types.CALL_ORDER_PRINT_CHALLAN})
  data()
}

export const setAllfuturedatesforShop=(data)=>(dispatch)=>{
  dispatch({type:types.SET_ORDER_ALLOCATED_FUTURE_FOR_SHOP,payload:data})
}