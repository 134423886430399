import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Spinner,
  Input,
  useToast,
  Checkbox,
  InputGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Image,
} from "@chakra-ui/react";
import "./Date.css";
import { FaPrint, FaUser } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import { MdMarkEmailRead } from "react-icons/md";
import { getFilteredSubOrderData } from "../../../redux/Admin/AppReducer/orders/action";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import SuborderPopupChallan from "../../../SuborderChallan/SuborderPopupChallan";
import AllsuborderDatepicker from "./Datepickerfororder/AllsuborderDatepicker";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import { MdOutlineDateRange } from "react-icons/md";

const AllSuborder = () => {
  const { pathname } = useLocation();
  const datePick = useRef(null);
  const toast = useToast();
  let fil = pathname.split("/").splice(-1);
  const [orderstatus, setOrderStatus] = useState(fil[0]);
  const [searchbutton, setSearchButton] = useState(true);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.OrderReducer.isloading);
  const suborder = useSelector((state) => state.OrderReducer.order);
  const [arrofdates, setArrayofDates] = useState([]);
  const [startDate, setStartDate] = useState(null); // for calander
  const [endDate, setEndDate] = useState(null); // for calander
  const [showcalander, setShowCalander] = useState(false);
  const onOpenCalander = () => {
    setShowCalander(true);
  };
  const closeOpenCalander = () => {
    setShowCalander(false);
  };

  const [selected_suborder, setSelectedSuborder] = useState([]);
  const [selected_suborder_forcsv, setSelected_suborder_forcsv] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // Extract query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);

  // Get specific query parameters
  const filter = queryParams.get("filter") || null;
  const filterdata = queryParams.get("filterdata") || "all";

  let arr_of_button = [
    "All",
    "Allocated",
    "Accepted",
    "Printed",
    "Rejected",
    "Fulfilled",
    "Attempted",
    "Out_for_delivery",
    "Conflicted",
    "Cancelled",
    "hold",
  ];

  let arr_of_headings = [
    {
      name: "",
      style: {
        width: "38px",
        paddingRight: "10px", // Adjust the padding value as needed
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Order no.",
      style: {
        width: "42px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Sub-Order no.",
      style: {
        width: "42px",

        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Delivery date",
      style: {
        width: "76px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
    {
      name: "Time Slot",
      style: {
        width: "30px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "15px",
      },
    },
    {
      name: "Name",
      style: {
        width: "40px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "20px",
      },
    },
    {
      name: "Phone no.",
      style: {
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "5px",
      },
    },
    {
      name: "City",
      style: {
        width: "40px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "3px",
      },
    },
    {
      name: "items",
      style: {
        width: "35px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Order Amount",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Payment mode",
      style: {
        width: "65px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Product name",
      style: {
        width: "52px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Allocated Vendor",
      style: {
        width: "65px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
    {
      name: "Order Status",
      style: {
        width: "40px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Driver Name",
      style: {
        width: "50px",
        marginLeft: "15px",
        whiteSpace: "normal",
        wordBreak: "break-word",
      },
    },
    {
      name: "Driver Status",
      style: {
        width: "50px",
        whiteSpace: "normal",
        wordBreak: "break-word",
        marginLeft: "10px",
      },
    },
  ];

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }

  function calculatetotalprice(item) {
    let price = item.line_items.reduce((acc, curr) => {
      return acc + Number(curr.price);
    }, 0);
    if (item.suborder_name.split("_")[1] == 1) {
      price = price + item.shipping_charges;
    }
    return price;
  }

  function handlePageChange(num) {
    setPage((pre) => pre + num);
  }
  function processString(inputString) {
    var processedString = inputString.toUpperCase().replace(/_/g, " ");
    return processedString;
  }

  // ******************  CONVERT DD-MM-YY in DD/MM/YY FORMAT ***************

  function formatDate(inputDate) {
    if (!inputDate) return;
    const dateParts = inputDate?.split("-");

    if (dateParts?.length === 3) {
      if (dateParts[0].length === 4) {
        return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      } else {
        return `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;
      }
    }
  }

  ////    CHECKBOX SELECT ALLLL
  function handleParentCheckbox(e) {
    let arr = [];
    for (let i = 0; i < suborder.length; i++) {
      let obj = {};
      obj.OrderNo = suborder[i].name;
      obj.subOrder_No = suborder[i].suborder_name;
      obj.Delivery_date = suborder[i].to_be_shipped_on;
      obj.Time_Slot = suborder[i].time_slot;
      obj.Name = suborder[i].shipping_address.name;
      obj.Phone_No = suborder[i].shipping_address.phone;
      obj.City = suborder[i].english_city;
      obj.Items = suborder[i].line_items.length;
      obj.Order_Amount = calculatetotalprice(suborder[i]);
      obj.Payment_mode =
        suborder[i].financial_status === "paid" ? "Paid" : "COD";
      obj.Product_name = suborder[i].line_items[0]?.name;
      obj.Allocated_vendor = suborder[i].allocated_vendor_name;
      obj.status = suborder[i].status;
      obj.Driver_name = suborder[i].allocated_driver_name;
      obj.Driver_Status =
        suborder[i].allocated_driver_status === "driver_allocated"
          ? null
          : suborder[i].allocated_driver_status;
      arr.push(obj);
    }

    if (selected_suborder.length === suborder?.length) {
      setSelectedSuborder([]);
      setSelected_suborder_forcsv([]);
    } else {
      setSelectedSuborder(suborder);
      setSelected_suborder_forcsv(arr);
    }
  }

  function handlechildrenCheckbox(i, item) {
    let csv_arr = [...selected_suborder_forcsv];
    let arr = [...selected_suborder];
    let obj = {};
    if (selected_suborder.some((k) => k.suborder_id === item.suborder_id)) {
      arr = arr.filter((k) => k.suborder_id !== item.suborder_id);
      csv_arr = csv_arr.filter((k) => k.subOrder_No !== item.suborder_name);
    } else {
      arr.push(item);
      obj.OrderNo = item.name;
      obj.subOrder_No = item.suborder_name;
      obj.Delivery_date = item.to_be_shipped_on;
      obj.Time_Slot = item.time_slot;
      obj.Name = item.shipping_address.first_name;
      obj.Phone_No = item.shipping_address.phone;
      obj.City = item.english_city;
      obj.Items = item.line_items.length;
      obj.Order_Amount = calculatetotalprice(item);
      obj.Payment_mode = item.financial_status === "paid" ? "Paid" : "COD";
      obj.Product_name = item.line_items[0]?.name;
      obj.Allocated_vendor = item.allocated_vendor_name;
      obj.status = item.status;
      obj.Driver_name = item.allocated_driver_name;
      obj.Driver_Status =
        item.allocated_driver_status === "driver_allocated"
          ? null
          : item.allocated_driver_status;
      csv_arr.push(obj);
    }
    setSelectedSuborder(arr);
    setSelected_suborder_forcsv(csv_arr);
  }

  //////   FOR CHALLAN
  const [challanshow, setChallanShow] = useState(false);
  const openchallanmodal = () => {
    setChallanShow(true);
  };
  const closechallanmodal = () => {
    setChallanShow(false);
  };

  /// FOR DOWNLOADING CSV

  // ****************************   FOR FILTERING THE SUBORDER *********************************
  const [selectedDataForFilter, setSelectedDataForFilter] = useState({
    filter: filter,
    filterdata: filterdata,
  });

  async function FilterSuborderDate(date) {
    let data = {
      filter: false,
      filterdata: "all",
    };
    if (date) {
      data = {
        filter: true,
        filterdata: date,
      };
    } else {
      data = {
        filter: false,
        filterdata: "all",
      };
    }
    setSelectedDataForFilter(data);
    setSearchParams(data);
    setPage(1);
    // dispatch(
    //   getFilteredSubOrderData(
    //     `${process.env.REACT_APP_API_URL}/suborder/${orderstatus}?page=${page}`,
    //     toast,
    //     navigate,
    //     data
    //   )
    // );
  }

  // function FilterSuborderHitAPi() {
  //   dispatch(
  //     getFilteredSubOrderData(
  //       `${process.env.REACT_APP_API_URL}/suborder/${orderstatus}?page=${page}&filter=${selectedDataForFilter.filter}&filterdata=${selectedDataForFilter.filterdata}`,
  //       toast,
  //       navigate,
  //       selectedDataForFilter
  //     )
  //   );
  // }

  function filterSuborder() {
    console.log(selectedDataForFilter,"djkhsd")
    dispatch(
      getFilteredSubOrderData(
        selectedDataForFilter.filter
          ? `${process.env.REACT_APP_API_URL}/suborder/${orderstatus}?page=${page}&filter=${selectedDataForFilter.filter}&filterdata=${selectedDataForFilter.filterdata}`
          : `${process.env.REACT_APP_API_URL}/suborder/${orderstatus}?page=${page}`,
        toast,
        navigate,
        selectedDataForFilter
      )
    );
  }

  function FilterSuborderName(e) {
    let data;
    if (e.target.value) {
      data = {
        filter: true,
        filterdata: e.target.value,
      };
    } else {
      data = {
        filter: false,
        filterdata: "all",
      };
    }
    setSelectedDataForFilter(data);
    setSearchParams(data);
  }
  useEffect(() => {
    setPage(1);
    filterSuborder();
  }, [orderstatus]);

  useEffect(() => {
    filterSuborder();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  // useEffect(() => {
  //   if (searchbutton) {
  //     filterSuborder();
  //   }
  // }, [searchbutton]);

  const capatilizedWord = (str) => {
    if (!str) return;
    let word = str.split(" ");
    let final = word.map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    });
    return final.join(" ");
  };

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => {
      filterSuborder();
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectedDataForFilter.filterdata]);

  ////// HOVERING ON ITEMS
  const [hoveredItemsShowProd, setHoveredItemsShowProd] = useState(null);

  const handleMouseEnteronItems = (index) => {
    setHoveredItemsShowProd(index);
  };

  const handleMouseLeaveonItems = () => {
    setHoveredItemsShowProd(null);
  };

  //// HOVERING ON PRODUCTSSS
  const [hoveredProd, setHoveredProd] = useState(null);

  const handleMouseEnteronProd = (index) => {
    setHoveredProd(index);
  };

  const handleMouseLeaveonProd = () => {
    setHoveredProd(null);
  };

  // **********************************  ALLOCATE TO VENDOR **************************************
  const [vendor, setVendor] = useState([]);
  const [loadervendor, setLoaderVendor] = useState(false);

  function getAvailableVendors() {
    if (loadervendor) {
      return;
    }
    setLoaderVendor(true);

    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "get",
      withCredentials: false,
      url: `${process.env.REACT_APP_API_URL}/suborder/getAvailableVendors`,
      headers: headers,
    })
      .then((r) => {
        setVendor(r.data);
        setLoaderVendor(false);
      })
      .catch((e) => {
        if (e.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        console.log(e);
        setToast(toast, `${e.response.data.err}`, "", "error");
        setLoaderVendor(false);
      });
  }

  return (
    <>
      <SuborderPopupChallan
        isOpen={challanshow}
        challan={selected_suborder}
        onClose={closechallanmodal}
      />

      <Box width="100%">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4" size="lg" fontWeight="medium">
            {processString(orderstatus)} SUB-ORDERS
          </Heading>
          <Flex mt="10px" gap="10px" justifyContent="flex-end">
            <Button
              bg="black"
              color="white"
              isDisabled={page === 1}
              onClick={() => handlePageChange(-1)}
            >
              previous Page
            </Button>
            <Box
              height="40px"
              pt="7px"
              width="40px"
              bg="black"
              borderRadius="10px"
              color="white"
            >
              <Text fontWeight="bold">{page}</Text>
            </Box>
            <Button
              bg="black"
              color="white"
              isDisabled={suborder.length < 25}
              onClick={() => handlePageChange(1)}
            >
              Next Page
            </Button>
          </Flex>
        </Flex>

        <br />
        <Flex
          borderRadius="7px"
          backgroundColor="white"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          justifyContent="space-between"
        >
          {/* {searchbutton ? ( */}
          <Flex
            justifyContent="space-between"
            p={{ base: "1", md: "1", lg: "2" }}
          >
            <Flex gap="10px" fontSize="10px">
              {arr_of_button.map((item, i) => (
                <Button
                  key={i}
                  colorScheme="teal"
                  variant={
                    orderstatus === item.toLocaleLowerCase() ? "solid" : "ghost"
                  }
                  fontSize="12px"
                  onClick={(e) => {
                    navigate(
                      `/admin/order/suborder/${item.toLocaleLowerCase()}`
                    );
                    setSelectedDataForFilter({
                      filter: null,
                      filterdata: "all",
                    });
                    setOrderStatus(item.toLocaleLowerCase());
                  }}
                >
                  {item.replaceAll("_", " ")}
                </Button>
              ))}
            </Flex>
          </Flex>
          {/* ) : (
              <Box></Box>
            )} */}
          {/* *******************   FILTER BUTTON ************************ */}
          <Flex
            margin="5px 5px 5px 0px"
            justifyContent="space-between"
            fontSize={{ base: "12px", md: "16px", lg: "26px" }}
          >
            {/* <Flex gap="10px">
                {searchbutton ? null : (
                  <Flex alignItems="center" gap="10px">
                    <Button onClick={FilterSuborderHitAPi}>
                      <FcSearch fontSize="26px" />
                    </Button>
                    <Flex gap="10px">
                      <Button>
                        <label for="#78342345" style={{ fontSize: "26px" }}>
                          <BsFillCalendarDateFill />
                        </label>
                        <input
                          type="date"
                          pattern="\d{2}-\d{2}-\d{4}"
                          id="#78342345"
                          class="datepicker"
                          onChange={(e) => FilterSuborderDate(e)}
                          name="datepicker"
                        />
                        <input
                          type="date"
                          style={{ display: "none" }}
                          ref={datePick}
                        />
                      </Button>
                      <Input
                        placeholder="search By order Name"
                        onChange={(e) => FilterSuborderName(e)}
                      />
                    </Flex>
                  </Flex>
                )}
                <IconButton
                  onClick={() => {
                    setSearchButton(!searchbutton);
                  }}
                  colorScheme={searchbutton ? "blue" : "red"}
                  aria-label="Search database"
                  icon={searchbutton ? <SearchIcon /> : <CloseIcon />}
                />
              </Flex> */}
          </Flex>
        </Flex>
        <br />

        <Flex justifyContent="space-between" fontSize="12px">
          <Flex
            width="150px"
            p="1px 10px 1px 10px"
            backgroundColor="white"
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            alignContent="center" // Align the content vertically to the center
            justifyContent="center"
          >
            <Flex alignItems="center" gap="10px">
              <Checkbox
                bg="white"
                size="lg"
                style={{ width: "24px", height: "24px" }}
                height="fit-content"
                onChange={handleParentCheckbox}
                isChecked={selected_suborder.length === suborder.length}
              />
              <Text>Select All</Text>
            </Flex>
          </Flex>

          {/* <Flex
            width="140px"
            p="1px 10px 1px 10px"
            backgroundColor="white"
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            alignItems="center"
            alignContent="center"
            _hover={{ cursor: "pointer" }}
            gap="10px"
            justifyContent="center"
            // onClick={()=>{
            //   getAvailableVendors()
            // }}
          >
            <FaUser fontSize="20px" color="gray" />
            <Text  >Allocate Vendor</Text>
          </Flex> */}
          {/* <Flex
            width="150px"
            p="1px 10px 1px 10px"
            backgroundColor="white"
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            alignItems="center"
            alignContent="center"
            _hover={{ cursor: "pointer" }}
            gap="10px"
            justifyContent="center"
          >
            <MdMarkEmailRead fontSize="25px" color="gray" />
            <Text>Send Email</Text>
          </Flex> */}
          <Flex direction="column">
            <Flex
              width="140px"
              height="100%"
              p="0px 10px 0px 10px"
              backgroundColor="white"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              onClick={() => {
                setShowCalander(true);
              }}
              _hover={{ cursor: "pointer" }}
              gap="10px"
            >
              <AiOutlineDownload fontSize="20px" color="gray" />
              <Text>Download CSV</Text>
            </Flex>
          </Flex>

          <Flex
            width="140px"
            p="0px 10px 0px 10px"
            backgroundColor="white"
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            justifyContent="center"
            onClick={openchallanmodal}
            alignItems="center"
            alignContent="center"
            _hover={{ cursor: "pointer" }}
            gap="10px"
          >
            <FaPrint fontSize="20px" color="gray" />
            <Text>Print Challan</Text>
          </Flex>

          {/* date filter */}

          <Flex
            width="140px"
            // p="0px 10px 0px 10px"
            // backgroundColor="white"
            // boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            // justifyContent="center"
            // // onClick={openchallanmodal}
            // alignItems="center"
            // alignContent="center"
            // _hover={{ cursor: "pointer" }}
            // gap="10px"
          >
            <Button bg="white">
              <label
                for="#78342345"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 10px 0px 10px",
                  justifyContent: "center",
                  gap: "10px",
                }}
                // style={{ fontSize: "15px", color: "gray" }}
              >
                <MdOutlineDateRange fontSize="20px" color="gray" />
                <Text fontSize={"12px"}>Filter By Date</Text>
              </label>
              <input
                type="date"
                pattern="\d{2}-\d{2}-\d{4}"
                id="#78342345"
                class="datepicker"
                onChange={(e) => {
                  if (!e.target.value) {
                    console.log("insdfan");
                    setSelectedDataForFilter({
                      filter: null,
                      filterdata: "all",
                    });
                    navigate(
                      `/admin/order/suborder/${orderstatus.toLocaleLowerCase()}`
                    );
                    
                  }
                  FilterSuborderDate(e.target.value);
                }}
                name="datepicker"
              />
              <input type="date" style={{ display: "none" }} ref={datePick} />
            </Button>
            {/* <MdOutlineDateRange fontSize="20px" color="gray" />
            <Text>Filter By Date</Text> */}
          </Flex>
          <Box
            p="0px 10px 0px 10px"
            backgroundColor="white"
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            justifyContent="space-between"
            position="relative" // Ensure the input takes full space within the Box
          >
            <InputGroup>
              <SearchIcon color="gray.300" h={10} fontSize="30px" />
              <Input
                placeholder="Search Order"
                onChange={FilterSuborderName}
                style={{
                  border: "0px solid white", // Remove the border
                  width: "100%", // Make the input fill the entire Box horizontally
                  padding: "0", // Remove any default padding
                  margin: "0", // Remove any default margin
                  outline: "none",
                }}
              />
            </InputGroup>
          </Box>
        </Flex>

        {showcalander && (
          <AllsuborderDatepicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            isOpen={showcalander}
            onClose={closeOpenCalander}
            arrofdates={arrofdates}
            setArrayofDates={setArrayofDates}
            status={orderstatus}
          />
        )}
        <br />
        {isLoading ? (
          <Spinner
            mt="30px"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <div
            style={{
              borderRadius: "7px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              backgroundColor: "white",
            }}
          >
            <hr />
            <br />
            {suborder.length > 0 ? (
              <TableContainer mt="-20px" width="100%" textAlign="left">
                <Table variant="simple">
                  <Thead p="0px 0px 0px 5px" bg="gray.100">
                    <Tr p="0px 0px 0px 5px" bg="gray.100">
                      {arr_of_headings.map((name) => {
                        return (
                          <Th textAlign="left" p="0px 0px 0px 5px">
                            <Text
                              fontSize="10px"
                              style={{ ...name.style, textAlign: "left" }}
                            >
                              {name.name}
                            </Text>
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {suborder?.map((item, i) => (
                      <Tr
                        fontSize="12px"
                        key={i}
                        _hover={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/admin/order/suborder/${orderstatus}/${item.suborder_id}`
                          )
                        }
                        width="100%"
                      >
                        <Td
                          width="30px"
                          p="0px 0px 0px 5px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox
                            size="lg"
                            style={{ width: "24px", height: "24px" }}
                            onChange={() => handlechildrenCheckbox(i, item)}
                            isChecked={selected_suborder.some(
                              (k) => k.suborder_id === item.suborder_id
                            )}
                          />
                        </Td>
                        <Td
                          width="50px"
                          p="0px"
                          pl="6px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {item?.name}
                        </Td>
                        <Td
                          width="50px"
                          p="0px"
                          pl="4px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {item?.suborder_name}
                        </Td>
                        <Td
                          width="50px"
                          p="0px"
                          pl="10px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {formatDate(item?.to_be_shipped_on)}
                        </Td>
                        <Td
                          whiteSpace="normal"
                          wordBreak="break-word"
                          p="0px"
                          width="80px"
                        >
                          {item?.time_slot}
                        </Td>
                        <Td
                          whiteSpace="normal"
                          wordBreak="break-word"
                          p="0px"
                          width="80px"
                          textAlign="center"
                        >
                          {capatilizedWord(item?.shipping_address.name)}
                        </Td>
                        <Td
                          width="80px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl="5px"
                        >
                          {item?.shipping_address.phone}
                        </Td>
                        <Td
                          width="40px"
                          p="0px"
                          pl="8px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {capatilizedWord(item?.english_city)}
                        </Td>
                        <Td
                          width="35px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl="3px"
                          onMouseEnter={() => handleMouseEnteronItems(i)}
                          onMouseLeave={handleMouseLeaveonItems}
                        >
                          <Popover
                            key={i}
                            isOpen={hoveredItemsShowProd === i}
                            onClose={() => setHoveredItemsShowProd(null)}
                            placement="right"
                          >
                            <PopoverTrigger>
                              <Box
                                onMouseEnter={() => handleMouseEnteronItems(i)}
                                onMouseLeave={handleMouseLeaveonItems}
                                cursor="pointer"
                              >
                                {item?.line_items?.length} Items
                              </Box>
                            </PopoverTrigger>
                            <PopoverContent
                              onMouseEnter={() => handleMouseEnteronItems(i)}
                              onMouseLeave={handleMouseLeaveonItems}
                              width="320px"
                            >
                              <PopoverBody>
                                <Flex p="5px" gap="10px" direction="column">
                                  {item.line_items.map((prod) => (
                                    <Flex
                                      justifyContent="space-between"
                                      gap="5px"
                                      alignContent="center"
                                      alignItems="center"
                                    >
                                      <Flex
                                        gap="10px"
                                        alignContent="center"
                                        alignItems="center"
                                      >
                                        <Image
                                          src={
                                            prod.properties.find(
                                              (k) => k.name === "_product_image"
                                            )?.value
                                          }
                                          h="60px"
                                          w="60px"
                                          borderRadius="20px"
                                        />
                                        <Flex direction="column">
                                          <Text
                                            color="blue"
                                            fontSize="16px"
                                            fontWeight="semibold"
                                            textAlign="left"
                                          >
                                            {prod.name}
                                          </Text>
                                          <Text
                                            color="gray"
                                            fontSize="14px"
                                            fontWeight="semibold"
                                            textAlign="left"
                                          >
                                            {prod.sku}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                      <Flex>
                                        <Text
                                          fontSize="20px"
                                          fontWeight="semibold"
                                        >
                                          x
                                        </Text>
                                        <Text
                                          fontSize="20px"
                                          fontWeight="semibold"
                                        >
                                          {prod.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  ))}
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td
                          width="35px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl="16px"
                        >
                          {calculatetotalprice(item)}
                        </Td>
                        <Td
                          width="50px"
                          p="0p"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          pl={"18px"}
                        >
                          {item?.financial_status === "paid" ? "Paid" : "COD"}
                        </Td>

                        <Td
                          width="60px"
                          p="0px"
                          pl="4px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          onMouseEnter={() => handleMouseEnteronProd(i)}
                          onMouseLeave={handleMouseLeaveonProd}
                        >
                          <Popover
                            key={i}
                            isOpen={hoveredProd === i}
                            onClose={() => setHoveredProd(null)}
                            placement="right"
                          >
                            <PopoverTrigger>
                              <Box
                                onMouseEnter={() => handleMouseEnteronProd(i)}
                                onMouseLeave={handleMouseLeaveonProd}
                                cursor="pointer"
                              >
                                {truncateString(item.line_items[0]?.name, 10)}
                              </Box>
                            </PopoverTrigger>
                            <PopoverContent
                              onMouseEnter={() => handleMouseEnteronProd(i)}
                              onMouseLeave={handleMouseLeaveonProd}
                              width="fit-content"
                            >
                              <PopoverBody>
                                <Flex p="5px" gap="10px" direction="column">
                                  {item.line_items.map((prod) => (
                                    <Flex
                                      direction="column"
                                      justifyContent="center"
                                    >
                                      <Text
                                        fontSize="16px"
                                        fontWeight="semibold"
                                        textAlign="left"
                                      >
                                        {prod.name}
                                      </Text>
                                      <Text
                                        fontSize="14px"
                                        fontWeight="semibold"
                                        textAlign="left"
                                      >
                                        {prod.sku}
                                      </Text>
                                    </Flex>
                                  ))}
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>

                        <Td
                          width="100px"
                          p="0px"
                          pl="2px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {capatilizedWord(item?.allocated_vendor_name)}
                        </Td>
                        <Td
                          width="55px"
                          p="0px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {capatilizedWord(item?.status)}
                        </Td>
                        <Td
                          width="80px"
                          p="0px 0px 0px 10px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {/* {new Date(
                            item.created_at?.split("T")[0]
                          )?.toLocaleDateString("en-GB")} */}
                          {capatilizedWord(item?.allocated_driver_name)}
                        </Td>
                        <Td
                          width="80px"
                          p="0px"
                          pl="5px"
                          whiteSpace="normal"
                          wordBreak="break-word"
                        >
                          {item?.allocated_driver_status ===
                            "out_for_delivery" ||
                          item?.allocated_driver_status === "attempted" ||
                          item?.allocated_driver_status === "fulfilled"
                            ? capatilizedWord(item?.allocated_driver_status)
                            : null}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Flex justifyContent="center" mt="50px" paddingBottom="50px">
                <Text color="red" fontSize="20px" fontWeight="semibold">
                  No Data Available on this Page
                </Text>
              </Flex>
            )}
          </div>
        )}
        <Flex mt="10px" gap="10px" justifyContent="flex-end">
          <Button
            bg="black"
            color="white"
            isDisabled={page === 1}
            onClick={() => handlePageChange(-1)}
          >
            previous Page
          </Button>
          <Box
            height="40px"
            pt="7px"
            width="40px"
            bg="black"
            borderRadius="10px"
            color="white"
          >
            <Text fontWeight="bold">{page}</Text>
          </Box>
          <Button
            bg="black"
            color="white"
            isDisabled={suborder.length < 25}
            onClick={() => handlePageChange(1)}
          >
            Next Page
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default AllSuborder;
