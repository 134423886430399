import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  useDisclosure,
  TableContainer,
  Input,
  Grid,
  Badge,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CreateShippingMethodDetails,
  DeleteShippingMethodDetails,
  getShippingMethodsData,
} from "../../../redux/Admin/AppReducer/Shipping/action";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import { FcDownload } from "react-icons/fc";
import axios from "axios";
import { saveAs } from "file-saver";
import csvtojson from "csvtojson";
import { CiEdit } from "react-icons/ci";
import { utils as XLSXUtils, write as writeToBuffer } from "xlsx";
import { setToast } from "../../../Fucntions/Toastfunction";
import { updateLocale } from "moment";

const ShippingMethodList = () => {
  const toast = useToast();
  const [editEnable, setEditEnable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shipping = useSelector((state) => state.ShippingReducer.shipping);
  const shippingisLoading = useSelector(
    (state) => state.ShippingReducer.isloading
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shippingdetails, setShippingDetails] = useState({
    name: "",
    display_name: "",
    arabic_name: "",
    display_arabic_name: "",
    arabic_message: "",
    arabic_warning_message: "",
    shipping_cut_off: null,
    message: "",
    price: "",
    warning_message: "",
    time_slots: [],
  });
  const [timeslot, setTimeSlot] = useState({
    start: "",
    end: "",
  });
  const [IsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState();
  const openModal = (name) => {
    setName(name);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  //  *************   FOR EDTING SHIPPING METHOD ******************
  let [editingtrue, setEditingTrue] = useState(false);
  function editsippingMethods(item) {
    setEditingTrue(true);
    setShippingDetails(item);
    onOpen();
    console.log(item, "item");
  }
  function handleEditEnable() {
    setEditEnable(true);
  }

  function handletimeslotInArray(e) {
    const { name, value } = e.target;
    setTimeSlot({
      ...timeslot,
      [name]: value,
    });
  }

  function handlepushtimeslotinArray() {
    let newdetailobj = { ...shippingdetails };
    newdetailobj.time_slots.push(timeslot);
    setTimeSlot({
      start: "",
      end: "",
    });
    setShippingDetails(newdetailobj);
  }

  function handleshippingDetails(e) {
    const { name, value } = e.target;

    // Check if the first character is a space
    if (value.charAt(0) === " ") {
      // Remove the first character (space)
      e.target.value = value.slice(1);
      return;
    }
    if (name === "shipping_cut_off") {
      setShippingDetails({
        ...shippingdetails,
        [name]: +value,
      });
      return;
    }
    setShippingDetails({
      ...shippingdetails,
      [name]: value,
    });
  }

  console.log(shippingdetails, "details");

  function removeselectedtimeSlots(index) {
    let newdetailobj = { ...shippingdetails };
    newdetailobj.time_slots.splice(index, 1);
    setShippingDetails(newdetailobj);
  }

  const [loader, setLoader] = useState(false);

  async function handleSubmitAndHitApi() {
    if (loader) {
      return;
    }
    setLoader(true);
    await dispatch(
      CreateShippingMethodDetails(
        `${process.env.REACT_APP_API_URL}/shipping/create`,
        shippingdetails,
        toast,
        `${process.env.REACT_APP_API_URL}/shipping/read`,
        navigate,
        onClose,
        setShippingDetails
      )
    );
    setLoader(false);
  }
  const [downloadLoading, setDownLoadLoading] = useState(false);
  function handleDownlaodShippingData() {
    setDownLoadLoading(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/shipping/download`,
      headers,
    })
      .then((response) => {
        csvtojson()
          .fromString(response.data)
          .then((jsonData) => {
            const workbook = { SheetNames: ["Sheet1"], Sheets: {} };
            const worksheet = XLSXUtils.json_to_sheet(jsonData);
            workbook.Sheets["Sheet1"] = worksheet;
            const excelBuffer = writeToBuffer(workbook, { type: "buffer" });
            const blob = new Blob([excelBuffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, "filename.xlsx");
          });
        setDownLoadLoading(false);
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        // ?  console.log(err);
        setToast(toast, "something Went Wrong", "", "error");
        setDownLoadLoading(false);
      });
  }

  let [deleteLoader, setDeleteLoader] = useState(false);
  async function handleDeleteShippingMethodHitApi() {
    if (deleteLoader) {
      return;
    }
    setDeleteLoader(true);
    await dispatch(
      DeleteShippingMethodDetails(
        `${process.env.REACT_APP_API_URL}/shipping/delete`,
        name,
        toast,
        `${process.env.REACT_APP_API_URL}/shipping/read`,
        closeModal,
        navigate
      )
    );
    setDeleteLoader(false);
  }

  let [updateloader, setUpdateLoader] = useState(false);
  function updateShippingMethodHitApi() {
    console.log(shippingdetails, "detailsssssssssssss");
    if (updateloader) {
      return;
    }
    setUpdateLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/shipping/update`,
      data: { data: shippingdetails },
      headers,
    })
      .then((r) => {
        setEditingTrue(false);
        console.log(r.data);
        setUpdateLoader(false);
        dispatch(
          getShippingMethodsData(
            `${process.env.REACT_APP_API_URL}/shipping/read`
          )
        );
        onClose();
        setToast(
          toast,
          "Shipping Method Updated Successfully ",
          "",
          "success",
          "top"
        );
      })
      .catch((err) => {
        setEditingTrue(false);
        console.log(err);
        setUpdateLoader(false);
        onClose();
        setToast(toast, `Updating failed try again`, "", "error", "top");
      });
  }
  useEffect(() => {
    dispatch(
      getShippingMethodsData(`${process.env.REACT_APP_API_URL}/shipping/read`)
    );
  }, []);

  return (
    <>
      {shippingisLoading ? (
        <Text
          color="gray.800"
          fontSize="40px"
          fontWeight="semibold"
          mt="80px"
          pb="80px"
        >
          Loading ...
        </Text>
      ) : (
        <Box>
          <Flex justifyContent="space-between">
            <Heading as="h4" size="lg" fontWeight="medium">
              Shipping Methods
            </Heading>

            <Box>
              {editEnable ? (
                <>
                  <Flex gap="20px">
                    {downloadLoading ? (
                      <Button>
                        <Spinner />
                      </Button>
                    ) : (
                      <Button
                        variant="outline"
                        border="2px solid teal"
                        leftIcon={<FcDownload />}
                        onClick={handleDownlaodShippingData}
                      >
                        Bulk Download
                      </Button>
                    )}
                    <Button
                      onClick={() => setEditEnable(false)}
                      colorScheme="blue"
                    >
                      Cancel
                    </Button>
                    <Button onClick={onOpen} colorScheme="whatsapp">
                      Add Shipping Methods
                    </Button>
                  </Flex>
                  <Modal
                    closeOnOverlayClick={false}
                    onClose={onClose}
                    size="2xl"
                    isOpen={isOpen}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        <Heading
                          as="h3"
                          size="lg"
                          fontWeight="medium"
                          mb="10px"
                        >
                          Add New Shipping Method
                        </Heading>
                      </ModalHeader>
                      <ModalCloseButton
                        onClick={() => {
                          setTimeSlot({
                            start: "",
                            end: "",
                          });
                          setShippingDetails({
                            name: "",
                            display_name: "",
                            arabic_name: "",
                            display_arabic_name: "",
                            arabic_message: "",
                            arabic_warning_message: "",
                            shipping_cut_off: null,
                            message: "",
                            price: "",
                            sequence: "",
                            warning_message: "",
                            time_slots: [],
                          });
                        }}
                      />
                      <ModalBody width="100%">
                        {/* **************** Shipping Methods Started ****************** */}
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4"> Name :</Text>
                          <Input
                            flex={9}
                            name="name"
                            value={shippingdetails.name}
                            placeholder="Enter Name"
                            onChange={handleshippingDetails}
                          />
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Display Name :</Text>
                          <Input
                            flex={9}
                            name="display_name"
                            value={shippingdetails.display_name}
                            placeholder="Enter Dispaly Name"
                            onChange={handleshippingDetails}
                          />
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Message :</Text>

                          <FormControl as="fieldset" flex={10}>
                            <Input
                              value={shippingdetails.message}
                              placeholder="Enter Message"
                              flex={9}
                              name="message"
                              onChange={handleshippingDetails}
                            />
                          </FormControl>
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4"> Warning message :</Text>
                          <Flex
                            p="1"
                            flex={10}
                            gap="15px"
                            justifyContent="space-between"
                          >
                            <Input
                              value={shippingdetails.warning_message}
                              placeholder="Enter Warning Message"
                              name="warning_message"
                              onChange={handleshippingDetails}
                            />
                          </Flex>
                        </Flex>

                        {/* ******************  Fields in Arabic  ************************************* */}

                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Arabic Name :</Text>
                          <Input
                            flex={9}
                            name="arabic_name"
                            value={shippingdetails.arabic_name}
                            placeholder="Enter Arabic Name"
                            onChange={handleshippingDetails}
                          />
                        </Flex>

                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Arabic Display Name :</Text>
                          <Input
                            flex={9}
                            name="display_arabic_name"
                            value={shippingdetails.display_arabic_name}
                            placeholder="Enter Arabic Display Name"
                            onChange={handleshippingDetails}
                          />
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Arabic Message :</Text>

                          <FormControl as="fieldset" flex={10}>
                            <Input
                              placeholder="Enter Arabic Message"
                              flex={9}
                              value={shippingdetails.arabic_message}
                              name="arabic_message"
                              onChange={handleshippingDetails}
                            />
                          </FormControl>
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Arabic Warning message :</Text>
                          <Flex
                            p="1"
                            flex={10}
                            gap="15px"
                            justifyContent="space-between"
                          >
                            <Input
                              value={shippingdetails.arabic_warning_message}
                              placeholder="Enter Arabic Warning Message"
                              name="arabic_warning_message"
                              onChange={handleshippingDetails}
                            />
                          </Flex>
                        </Flex>

                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4"> Price :</Text>
                          <Flex
                            p="1"
                            flex={10}
                            gap="15px"
                            justifyContent="space-between"
                          >
                            <Input
                              value={shippingdetails.price}
                              placeholder="Enter Price"
                              name="price"
                              type="number"
                              onChange={handleshippingDetails}
                            />
                          </Flex>
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4"> Cut Off :</Text>
                          <Flex
                            p="1"
                            flex={10}
                            gap="15px"
                            justifyContent="space-between"
                          >
                            <Input
                              value={shippingdetails.shipping_cut_off}
                              type="number"
                              placeholder="Enter Cut Off Time"
                              name="shipping_cut_off"
                              onChange={handleshippingDetails}
                            />
                          </Flex>
                        </Flex>

                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4"> Sequence Number :</Text>
                          <Flex
                            p="1"
                            flex={10}
                            gap="15px"
                            justifyContent="space-between"
                          >
                            <Input
                              value={shippingdetails.sequence}
                              placeholder="Enter Sequence Number"
                              name="sequence"
                              type="number"
                              onChange={handleshippingDetails}
                            />
                          </Flex>
                        </Flex>

                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Time Slot :</Text>
                          <FormControl as="fieldset" flex={10}>
                            <Flex justifyContent="space-between" flex={8}>
                              <Flex alignItems="center" gap="20px">
                                <Text>From</Text>
                                <Input
                                  type="time"
                                  name="start"
                                  value={timeslot.start}
                                  onChange={handletimeslotInArray}
                                />
                              </Flex>
                              <Flex alignItems="center" gap="20px">
                                <Text>To</Text>
                                <Input
                                  value={timeslot.end}
                                  type="time"
                                  name="end"
                                  onChange={handletimeslotInArray}
                                />
                              </Flex>
                            </Flex>
                          </FormControl>
                          <IconButton
                            isDisabled={
                              timeslot.end === "" || timeslot.start === ""
                            }
                            onClick={handlepushtimeslotinArray}
                            colorScheme="twitter"
                            icon={<AddIcon />}
                          />
                        </Flex>
                        <Flex gap="20px" alignItems="center" mb="20px">
                          <Text flex="4">Time Slots :</Text>
                          <Flex
                            border="1px solid #EDF2F9"
                            minHeight="100px"
                            p="1"
                            flex={10}
                            gap="15px"
                          >
                            <Grid
                              templateColumns={{
                                base: "repeat(2, 1fr)",
                                md: "repeat(3,1fr)",
                                lg: "repeat(3,1fr)",
                              }}
                              gap="15px"
                            >
                              {shippingdetails.time_slots.map((item, i) => (
                                <Badge height="25px" mt="8px" key={i}>
                                  <CloseIcon
                                    fontSize="24px"
                                    padding="5px"
                                    borderRadius="50%"
                                    onClick={() => removeselectedtimeSlots(i)}
                                    _hover={{
                                      cursor: "pointer",
                                      transition: "0.2s",
                                      transform: "scale(1.03)",
                                      boxShadow: "0 0 2px black",
                                    }}
                                  />
                                  {item.start}-{item.end}
                                </Badge>
                              ))}
                            </Grid>
                          </Flex>
                        </Flex>
                      </ModalBody>
                      <ModalFooter>
                        {editingtrue ? (
                          <Button
                            colorScheme="green"
                            marginRight="20px"
                            onClick={updateShippingMethodHitApi}
                          >
                            {loader ? <Spinner /> : "UPDATE"}
                          </Button>
                        ) : (
                          <Button
                            colorScheme="green"
                            marginRight="20px"
                            onClick={handleSubmitAndHitApi}
                          >
                            {loader ? <Spinner /> : "SUBMIT"}
                          </Button>
                        )}

                        <Button
                          colorScheme="red"
                          onClick={() => {
                            onClose();
                            setTimeSlot({
                              start: "",
                              end: "",
                            });
                            setShippingDetails({
                              name: "",
                              display_name: "",
                              arabic_name: "",
                              display_arabic_name: "",
                              arabic_message: "",
                              arabic_warning_message: "",
                              shipping_cut_off: null,
                              message: "",
                              price: "",
                              warning_message: "",
                              time_slots: [],
                            });
                          }}
                        >
                          CANCEL
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </>
              ) : (
                <Flex gap="10px">
                  {downloadLoading ? (
                    <Button>
                      <Spinner />
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      border="2px solid teal"
                      leftIcon={<FcDownload />}
                      onClick={handleDownlaodShippingData}
                    >
                      Bulk Download
                    </Button>
                  )}
                  <Button colorScheme="facebook" onClick={handleEditEnable}>
                    Edit
                  </Button>
                </Flex>
              )}
            </Box>
          </Flex>
          <br />
          <div
            style={{
              borderRadius: "7px",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              backgroundColor: "white",
            }}
            height="30px"
          >
            <hr />
            <br />
            <TableContainer>
              <Table variant="unstyled">
                <Thead>
                  <Tr>
                    <Th>
                      <Text fontSize="sm" width="150px">
                        Name
                      </Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm" width="150px">
                        Display Name
                      </Text>
                    </Th>
                    <Th width="100px">
                      <Text fontSize="sm">Message</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Warning Message</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Price</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Cut Off</Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm">Time Slots</Text>
                    </Th>
                    {editEnable ? (
                      <>
                        <Th>
                          <Text fontSize="sm">Edit</Text>
                        </Th>
                        <Th>
                          <Text fontSize="sm">Delete</Text>
                        </Th>
                      </>
                    ) : null}
                  </Tr>
                </Thead>
                <Tbody>
                  {shipping.map((item, i) => (
                    <Tr key={i}>
                      {/* {console.log(item)} */}
                      <Td
                        width="70px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                      >
                        <Flex flexDirection="column">
                          <Text> {item.name}</Text>
                          <Text> ({item.arabic_name})</Text>
                        </Flex>
                      </Td>
                      <Td
                        width="70px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                      >
                        <Flex flexDirection="column">
                          <Text> {item.display_name}</Text>
                          {item.display_arabic_name && (
                            <Text> ({item.display_arabic_name})</Text>
                          )}
                        </Flex>
                      </Td>
                      <Td
                        width="70px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                      >
                        <Flex flexDirection="column">
                          <Text> {item.message}</Text>
                          <Text> ({item.arabic_message})</Text>
                        </Flex>
                      </Td>
                      <Td
                        width="50px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                      >
                        <Flex flexDirection="column">
                          <Text> {item.warning_message}</Text>
                          {item.arabic_warning_message && (
                            <Text> ( {item.arabic_warning_message} )</Text>
                          )}
                        </Flex>
                      </Td>
                      <Td>{item.price}</Td>
                      <Td>{item.shipping_cut_off}</Td>
                      <Td
                        width="100px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                      >
                        <>
                          <Grid templateColumns="repeat(2,1fr)" gap="10px">
                            {item.time_slots.map((item, i) => (
                              <Badge
                                key={i}
                                fontSize="16px"
                                p="1"
                                width="fit-content"
                              >
                                {item.start} - {item.end}
                              </Badge>
                            ))}
                          </Grid>
                        </>
                      </Td>
                      {editEnable ? (
                        <>
                          <Td>
                            <IconButton
                              colorScheme="blue"
                              onClick={() => editsippingMethods(item)}
                              icon={<CiEdit fontSize="25px" />}
                            />
                          </Td>
                          <Td>
                            <IconButton
                              colorScheme="red"
                              onClick={() => openModal(item.name)}
                              icon={<CloseIcon />}
                            />
                          </Td>
                        </>
                      ) : null}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
          <ConfirmationModal
            heading="Delete Shipping Method"
            action={"Are you Sure? You want to Delete "}
            name={name}
            ConfirmButton="Yes Delete"
            onClickFunction={handleDeleteShippingMethodHitApi}
            isOpen={IsOpen}
            onClose={closeModal}
            loader={true}
            loading={loader}
          />
        </Box>
      )}
    </>
  );
};

export default ShippingMethodList;
