import {
  Badge,
  Box,
  Flex,
  Stack,
  Text,
  Button,
  Heading,
  Divider,
  Image,
  Toast,
  Link,
  useToast,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Radio,
  Input,
  Grid,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import TimelineComp from "./Timeline";
import PaidComp from "./PaidComp";
import { useSelector } from "react-redux";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModaldorAllocatevendor from "./ModaldorAllocatevendor";
import { useDispatch } from "react-redux";
import {
  OrderEditingAddProductAndVarinatId,
  OrderEditingNoteattr,
} from "../../../redux/Admin/AppReducer/orders/action";
import Partiallypaid from "./Partiallypaid";
import ModalForCityAndDate from "../orders/EditMainOrder/ModalForCityAndDate";
import ModalforEditOrder from "../orders/EditSubOrder/ModalforEditOrder";
import EditPropertiesAddressModal from "./EditPropertiesAddressModal";
import { BiCheckCircle } from "react-icons/bi";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import SuborderPopupChallan from "../../../SuborderChallan/SuborderPopupChallan";
import ConfirmationModal from "../../../Fucntions/Modals/ConfirmationModal";
import "./style/timeline.module.css";
import * as types from "../../../redux/Admin/AppReducer/orders/action.types";
import { manageNoteAttribute } from "../../../Fucntions/NoteAttribute";
import OnHoldButton from "./OnHold/OnHoldButton";
import OnHoldModal from "./OnHold/OnHoldModal";
import ForGreetingsMessage from "./ForGreetingsMessage";
import SpecialInstructions from "./SpecialInstructions";
import ModalForEditPPandWp from "./ModalForEditPPandWp";
import { formatDateString } from "../../../helpers/DateTimeHelper";
import moment from "moment";
import "moment-timezone";

const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [reason, setReason] = useState("");
  const [reasonArr, setReasonArr] = useState([]);
  const [isOpenModal3, setIsOpenModal3] = useState(false);
  const suborder = useSelector((state) => state.OrderReducer.order);
  console.log(suborder, "suborder");

  const [isLoading, setLoading] = useState(false);
  const [selectStatus, setSelectedStatus] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const openModal3 = () => {
    setIsOpenModal3(true);
  };
  const closeModal3 = () => {
    setIsOpenModal3(false);
  };

  // ****************************  EDIT PROPERTIES MODAL  ***************************************
  const [editpropertyAddressstatus, setEditPropertyAddressStatus] =
    useState(false);
  const openEditPropertyAddressModal = (item) => {
    setEditPropertyAddressStatus(true);
    dispatch(OrderEditingAddProductAndVarinatId([item, item.variant_id]));
  };
  const closeEditPropertyAddressModal = () => {
    setEditPropertyAddressStatus(false);
  };
  const saveReason = () => {
    setReasonArr([...reasonArr, reason]);
    setReason("");
    //(reasonArr)
    closeModal3();
  };
  // ***************************    MODAL FOR ALLOCATE VENDOR  *****************************************
  let [allocatetovendor, setAllocatetoVendor] = useState();
  let [relatedId, setRelatedId] = useState(null);
  const openAllocatetovendorModal = (relid) => {
    setRelatedId(relid);
    setAllocatetoVendor(true);
  };
  const closeAllocatedvendorModal = () => setAllocatetoVendor(false);

  //  ************************   ORDER EDITING START   ******************************************
  const handleOrderEditingStart = () => {
    //navigate(`/admin/order/suborder/editorder/${id}`);
  };

  // *************    MODAL FOR CITY AND DATE   *********************
  const [cityAndDateModalstatus, setCityAndDateModalStatus] = useState(false);

  const openCityAndDateModal = (item) => {
    dispatch(
      OrderEditingAddProductAndVarinatId([
        item.line_items[0],
        item.line_items[0].variant_id,
      ])
    );
    dispatch(OrderEditingNoteattr(item.note_attributes));
    //  onClose()

    setCityAndDateModalStatus(true);
  };
  const closeCityAndDateModal = () => setCityAndDateModalStatus(false);

  // ************************   ADD MORE DETAIL MODAL *******************************
  const [ordermodalopen, setOrderModalOpen] = useState(false);
  const OnOpenOrderModal = (item) => {
    setOrderModalOpen(true);
    dispatch(OrderEditingAddProductAndVarinatId([item, item.variant_id]));
  };
  const onCloseOrderModal = () => setOrderModalOpen(false);

  const [suborder_data, setSuborder_data] = useState([]);
  function getSuborderDataforSuborderDetail() {
    if (!suborder?.line_items) {
      return;
    }
    if (suborder.suborder_name) {
      return;
    }
    if (!suborder?.line_items[0]?.related_suborder) {
      return;
    }
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      headers,
      url: `${process.env.REACT_APP_API_URL}/suborder/read/${suborder?.line_items[0]?.related_suborder}`,
    })
      .then((r) => {
        //  console.log(r.data, "suborder _ detail +++++++++++++");
        setSuborder_data(r.data);
      })
      .catch((err) => {
        console.log(err, "errerrerr");
      });
  }

  useEffect(() => {
    getSuborderDataforSuborderDetail();
  }, [suborder]);

  let shipping_method =
    suborder?.shipping_method ||
    suborder?.note_attributes?.find((k) => k.name === "shipping_method")?.value;

  let shipping_date =
    suborder?.to_be_shipped_on ||
    suborder?.note_attributes?.find((k) => k.name === "shipping_date")?.value;

  let shipping_time =
    suborder?.time_slot ||
    suborder?.note_attributes?.find((k) => k.name === "shipping_time")?.value;

  function showBoxOrNot(arr) {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].fulfillment_status === "fulfilled") {
        return true;
      }
    }
    return false;
  }

  // ***********************  FUNCTIONALITY FOR ORDER ON HOLD  *****************************************
  let [onholdModal, setOnHoldModal] = useState(false);

  const openOnholdModal = () => {
    setOnHoldModal(true);
  };

  const closeOnHoldModal = () => {
    setOnHoldModal(false);
  };

  // ***********************  FUNCTIONALITY FOR ORDER UNHOLD  *****************************************

  function handleReleaseHold() {
    let user = JSON.parse(sessionStorage.getItem("username"));
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      url: `${process.env.REACT_APP_API_URL}/order/unhold/${id}`,
      method: "post",
      data: { user },
      headers,
    })
      .then((r) => {
        setToast(
          toast,
          `ORDER ${r.data?.data?.data.fulfillmentOrderReleaseHold?.fulfillmentOrder.status}`,
          "",
          "success"
        );
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            navigate
          )
        );
      })
      .catch((err) => {
        setToast(toast, "something went wrong", "", "error");
      });
  }

  function extractObjdata(obj) {
    let valuesArray = [];

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        valuesArray.push(obj[key]);
      }
    }
    return valuesArray;
  }

  function ForSelectingWhichIsSuborder(arr = []) {
    let clone = [...arr];
    let parent = [];

    if (pathname === `/admin/order/${id}`) {
      // console.log("order");
      let SuborderCheck = [];
      clone.forEach((item) => {
        if (!item.related_suborder) {
          parent.push([item]);
        } else {
          if (!SuborderCheck.includes(item.related_suborder)) {
            const children = clone.filter(
              (x) => x.related_suborder === item.related_suborder
            );
            parent.push(children);
            SuborderCheck.push(item.related_suborder);
          }
        }
      });

      const newParent = parent.map((child) => {
        if (child.length > 1) {
          let parentItem = [];
          let children = [];
          child.forEach((item) => {
            if (
              item.properties.map((prop) => prop.name).includes("_parent_id")
            ) {
              children.push(item);
            } else {
              parentItem.push(item);
            }
          });
          return [...parentItem, ...children];
        } else {
          return child;
        }
      });

      return newParent;
    } else {
      for (let k = 0; k < clone.length; k++) {
        if (!clone[k].properties.some((m) => m.name === "_parent_id")) {
          clone.unshift(parent[k]);
          clone.splice(k, 1);
        }

        return [clone];
      }
    }
  }
  // ***************************   BULK CHALLAN WORK  ************************************
  const [bulkchallan, setBulkchallan] = useState([]);

  let [bulkchallanstatus, setBulkchallanstatus] = useState(false);
  const openBulkChallanModal = () => {
    setBulkchallanstatus(true);
  };
  const closeBulkChallanmodal = () => {
    setBulkchallanstatus(false);
  };
  const toast = useToast();
  const [challanloader, setChallanLoader] = useState(false);

  function handlePrintBulkChallanProcess() {
    if (challanloader) {
      return;
    }
    // console.log("======================================")
    setChallanLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/order/${id}/suborders`,
      headers,
    })
      .then((r) => {
        // console.log(r.data,"|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
        setChallanLoader(false);
        setBulkchallan(r.data.suborders);
        openBulkChallanModal();
      })
      .catch((err) => {
        setChallanLoader(false);
        setToast(toast, "something went wrong", "", "error");
        // console.log(err)
      });
  }
  //  **************************** MODAL FOR ALL CHALLAN *****************************
  const [issuborderChallan, setIsSuborderChallan] = useState(false);
  const openSuborderChallanModal = () => {
    setIsSuborderChallan(true);
  };
  const closeSuborderChallanModal = () => {
    setIsSuborderChallan(false);
  };

  //  **************************** FOR DELETING ORDER *****************************
  const [orderdeleteconfirm, setorderDeleteConfirm] = useState(false);
  const onOpenOrderDelete = () => {
    setorderDeleteConfirm(true);
  };
  const onCloseOrderDelete = () => {
    setorderDeleteConfirm(false);
  };

  function deleteOrderBeginHitApi() {}

  const handleSuborderStatusUpdate = async (
    suborder_id,
    allocated_partner_id
  ) => {
    setLoading(true);
    if (!selectStatus) {
      console.error("Please select a status");
      return;
    }
    const REQ_URL = `${process.env.REACT_APP_API_URL}/suborder/admin_status_update`;
    const requestData = {
      suborder_ids: suborder_id,
      status: selectStatus,
      partner_id: allocated_partner_id,
    };

    // console.log("requestData", requestData);
    try {
      const response = await axios.put(REQ_URL, requestData, {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            sessionStorage.getItem("admintoken")
          )}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("Data Getting at Frontend", response.data);
      setLoading(false);
      closeModal();
      if (response.data) {
        toast({
          position: "top",
          title: "Status Updated",
          description: `Status changed Succesfully `,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Something went wrong",
          description: `Status not changed `,
          status: "failure",
          duration: 2000,
          isClosable: true,
        });
      }
      dispatch(
        getOrderData(
          `${process.env.REACT_APP_API_URL}/suborder/read/${id}`,
          navigate
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getOrderData = (url, navigate) => async (dispatch) => {
    dispatch({ type: types.GET_ORDER_DATA_LOADING });
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    return axios({
      method: "get",
      withCredentials: false,
      url: url,
      headers: headers,
    })
      .then((r) => {
        dispatch({ type: types.GET_ORDER_DATA_SUCCESS, payload: r.data });
      })
      .catch((err) => {
        if (err.response.data.err === "Invalid token") {
          sessionStorage.removeItem("admintoken");
          navigate("/admin/login");
          return;
        }
        dispatch({ type: types.GET_ORDER_DATA_ERROR });
        // setToast(toast, `${err.response.data.err}`, "", "error");
      });
  };

  // *******************************   FOR WEBSITE PRICE AND PURCHASE PRICE *************************************
  const [indexofppandwp, setIndexofppandwp] = useState(null);
  const [dataforppandwp, setDataforppandwp] = useState(null);
  const [typeofppandwp, setTypeofppandwp] = useState(null);
  const [priceforpp, setPricforpp] = useState(null);
  function onOpenmodalforppwp(index, obj, type) {
    console.log(obj, type);
    setDataforppandwp(obj);
    setTypeofppandwp(type);
    setIndexofppandwp(index);
  }
  function onClosemodalforppwp() {
    setIndexofppandwp(null);
  }

  function editWebsiteandPurchasePrice() {
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/order/editwpandpp`,
      data: {
        name: dataforppandwp.name,
        price: priceforpp,
        variant_id: dataforppandwp.variant_id,
        type: typeofppandwp,
      },
      headers,
    })
      .then((r) => {
        console.log(r.data);
        setToast(toast, "Price Updated", "", "success");
        onClosemodalforppwp();
        setDataforppandwp(null);
        setPricforpp(null);
        setTypeofppandwp(null);
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            navigate
          )
        );
      })
      .catch((err) => {
        console.log(err);
        setToast(toast, `${err.response.data.err}`, "", "error");
        onClosemodalforppwp();
        setDataforppandwp(null);
        setPricforpp(null);
        setTypeofppandwp(null);
      });
  }

  const isStatusChangeButtonVisible = [
    "allocated",
    "accepted",
    "attempted",
    "out_for_delivery",
  ].includes(suborder?.status);

  const renderModalContent = () => {
    switch (suborder?.status) {
      case "allocated":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="accepted">
                  Accept
                </Radio>
                <Radio colorScheme="red" value="rejected">
                  Reject
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      case "accepted":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="out_for_delivery">
                  Out for Delivery
                </Radio>
                <Radio colorScheme="green" value="fulfilled">
                  Fulfilled
                </Radio>
                <Radio colorScheme="green" value="attempted">
                  Attempted
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      case "attempted":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="fulfilled">
                  Fulfilled
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      case "out_for_delivery":
        return (
          <>
            <RadioGroup
              defaultValue={undefined}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value="attempted">
                  Attempted
                </Radio>
                <Radio colorScheme="green" value="fulfilled">
                  Fulfilled
                </Radio>
              </Stack>
            </RadioGroup>
          </>
        );

      default:
        return null;
    }
  };

  const checkmultipleimagornot = (item) => {
    let obj = item.find((k) => k.name === "_imagesUrl")?.value;
    let arr = obj.replace(/[\[\]"]/g, "").split(",");

    return arr;
  };

  function imagetoshow(item) {
    const imageProperty = item?.find(
      (property) => property.name.trim() === "_imagesUrl"
    );

    if (imageProperty) {
      return imageProperty?.value;
    }

    return null; // Return null if no image property was found
  }

  //  FOR WEBSITE AND PURCHASE PRICE FIND
  function forSearchingWpAndPP(orderdata, item, type) {
    let value;
    if (type === "pp") {
      value = orderdata?.variant_id_and_pp_wp?.find(
        (k) => k.variant_id === item.variant_id
      )?.price;
    } else {
      value = orderdata?.variant_id_and_pp_wp?.find(
        (k) => k.variant_id === item.variant_id
      )?.wp;
    }
    return value;
  }

  function forSearchingWpAndPPForEditing(orderdata, item) {
    let value;
    value = orderdata?.variant_id_and_pp_wp?.find(
      (k) => k.variant_id === item.variant_id
    );

    return value;
  }

  // ****************  FOR EDITING SENDER DETAIL ********************
  const [edit_sender_detail, set_edit_sender_detail] = useState(false);
  const [senderdetail, setSenderDetail] = useState(null);
  const [senderLoader, setSenderLoader] = useState(false);
  let sender_name = suborder?.note_attributes?.find(
    (k) => k.name === "sender_name"
  )?.value;

  function HandleSaveSenderDetail() {
    if (!senderdetail) {
      setToast(toast, "write something to edit", "", "error");
      return;
    }
    if (senderLoader) {
      return;
    }
    setSenderLoader(true);
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let new_note = JSON.parse(JSON.stringify(suborder.note_attributes));
    if (new_note.some((k) => k.name === "sender_name")) {
      new_note.find((k) => k.name === "sender_name").value = senderdetail;
    } else {
      new_note.push({ name: "sender_name", value: senderdetail });
    }
    let note_attr = manageNoteAttribute(new_note);
    let data = {
      note_attr,
      id,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/order/updatesendername`,
      data,
      headers,
    })
      .then((r) => {
        setSenderLoader(false);
        set_edit_sender_detail(false);
        setToast(toast, `sender name updated`, "", "success");
        dispatch(
          getOrderData(
            `${process.env.REACT_APP_API_URL}/order/read/${id}`,
            navigate
          )
        );
      })
      .catch((err) => {
        console.log(err);
        set_edit_sender_detail(false);
        setToast(toast, `${err.response.data.err}`, "", "error");
        setSenderLoader(false);
      });
  }

  // ****************  FOR EDITING CAKE MESSAGE ********************
  const [cake_message, setCake_message] = useState([]);
  const [cake_message_modal, setCake_message_modal] = useState(false);
  const [line_item_id_of_cake, setline_item_id_of_cake] = useState(null);
  const openCakeMessageModal = (line_item_id) => {
    setline_item_id_of_cake(line_item_id);
    setCake_message_modal(true);
  };
  const closeCakeMessageModal = () => {
    setCake_message_modal(false);
  };

  ////////  function for extaction item from the order mapping
  function finditem(order) {
    let item = order.find((k) =>
      k.properties.some((m) => m.name === "_parentProduct")
    );
    return item;
  }

  const capatilizedWord = (str) => {
    if (!str) return;
    let word = str.split(" ");
    let final = word.map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    });
    return final.join(" ");
  };

  return (
    <Box
      width={{ base: "100%", md: "90%", lg: "95%" }}
      color={"#f5f5f7"}
      margin="auto"
    >
      <OnHoldModal
        isOpen={onholdModal}
        onOpen={openOnholdModal}
        onClose={closeOnHoldModal}
      />
      <ModalforEditOrder
        isOpen={cake_message_modal}
        line_item_id={line_item_id_of_cake}
        onClose={closeCakeMessageModal}
      />
      <ConfirmationModal
        heading="Delete Order"
        action="Are you Sure? You want to Delete this Order"
        ConfirmButton="Yes Delete"
        onClickFunction={deleteOrderBeginHitApi}
        isOpen={orderdeleteconfirm}
        onClose={onCloseOrderDelete}
      />

      {suborder?.suborder_id ? (
        <SuborderPopupChallan
          challan={[suborder]}
          isOpen={issuborderChallan}
          onClose={closeSuborderChallanModal}
        />
      ) : (
        <SuborderPopupChallan
          isOpen={bulkchallanstatus}
          challan={bulkchallan}
          onClose={closeBulkChallanmodal}
        />
      )}
      <ModaldorAllocatevendor
        isOpen={allocatetovendor}
        onClose={closeAllocatedvendorModal}
        relatedId={relatedId}
      />

      {/* *****************  MODAL FOR EDIT PROPERTIES   ************************** */}
      <EditPropertiesAddressModal
        isOpen={editpropertyAddressstatus}
        onClose={closeEditPropertyAddressModal}
      />

      {/* *****************  MODAL FOR CITY  ************************** */}
      <ModalForCityAndDate
        isOpen={cityAndDateModalstatus}
        onClose={closeCityAndDateModal}
      />

      {/* *************************     MODAL FOR EDITING ORDER   ************************************* */}
      <ModalforEditOrder isOpen={ordermodalopen} onClose={onCloseOrderModal} />

      <Flex
        justifyContent="space-between"
        p={{ base: "1", md: "1", lg: "20px" }}
        backgroundColor="#fff"
        border="1px #ccc solid"
        borderRadius="10px"
      >
        <Flex alignContent={"left"} gap="30px">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            padding="10px"
            color="black"
            border="1px solid grey"
          >
            <MdKeyboardBackspace fontSize="30px" />
          </Button>

          <Stack direction="row" alignItems="center" p="2px">
            <Text
              fontSize={{ base: "12px", md: "16px", lg: "18px" }}
              color="black"
            >
              {suborder?.suborder_id
                ? suborder.suborder_name
                  ? suborder.suborder_name
                  : null
                : suborder.name}
            </Text>

            {!suborder?.suborder_id && (
              <Badge
                p="8px 10px 8px 10px"
                borderRadius={"20px"}
                colorScheme="purple"
              >
                {suborder.financial_status}
              </Badge>
            )}
            <Badge
              p="8px 10px 8px 10px"
              borderRadius={"20px"}
              colorScheme="purple"
            >
              {suborder?.status
                ? suborder.status
                : suborder.fulfillment_status
                ? suborder.fulfillment_status
                : "Unfulfilled"}
            </Badge>
          </Stack>
        </Flex>

        <Flex gap="10px">
          {isStatusChangeButtonVisible && (
            <Button colorScheme="teal" variant="outline" onClick={openModal}>
              Change status
            </Button>
          )}

          <Modal isOpen={isOpen} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent
              style={{
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ModalHeader style={{ marginTop: "15px" }}>
                Change status here
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody style={{ marginBottom: "15px" }}>
                {renderModalContent()}
                {isLoading ? (
                  <Spinner style={{ marginTop: "15px" }} />
                ) : (
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    mt={4}
                    onClick={() => {
                      handleSuborderStatusUpdate(
                        suborder.suborder_id,
                        suborder.currently_allocated_to
                      );
                    }}
                  >
                    Confirm
                  </Button>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          {suborder?.suborder_id && (
            <Button
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
              onClick={() => navigate(`/admin/order/${suborder?.parent_id}`)}
            >
              Go to Parent Order
            </Button>
          )}
          {!suborder?.suborder_id &&
            suborder?.fulfillment_status?.trim() === "ON_HOLD" && (
              <Button
                colorScheme="teal"
                variant="outline"
                border="1px solid teal"
                fontSize={{ base: "12px", md: "16px", lg: "16px" }}
                onClick={handleReleaseHold}
              >
                Release
              </Button>
            )}
          {!suborder?.suborder_id &&
            suborder?.fulfillment_status?.trim() === "UNFULFILLED" && (
              <OnHoldButton name="Hold" onClickFn={openOnholdModal} />
            )}
          {(suborder?.financial_status === "paid" ||
            suborder?.financial_status === "partially_paid") && (
            <a
              href={`https://admin.shopify.com/store/fnp-qatar/orders/${id}/refund`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {!suborder.suborder_name && (
                <Button
                  colorScheme="teal"
                  variant="outline"
                  border="1px solid teal"
                >
                  Refund
                </Button>
              )}
            </a>
          )}
          {suborder?.suborder_id ? (
            <Button
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              onClick={openSuborderChallanModal}
            >
              Print Challan
            </Button>
          ) : (
            <Button
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              onClick={handlePrintBulkChallanProcess}
            >
              {challanloader ? <Spinner /> : "Print All Challan"}
            </Button>
          )}

          {suborder?.id ? (
            <Button
              onClick={() => {
                navigate(`/admin/order/editorder/${id}`);
              }}
              colorScheme="teal"
              variant="outline"
              border="1px solid teal"
              fontSize={{ base: "12px", md: "16px", lg: "16px" }}
            >
              Edit
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <Flex m="10px 0" justifyContent={"space-evenly"}>
        <Box
          backgroundColor="white"
          borderRadius="7px"
          p="4"
          width="100%"
          boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          mt="20px"
        >
          {/* ***************************************   TOP EDITING ADDRESS  TIME AND DATE AND SENDER **************************** */}
          <Grid templateColumns="repeat(3,1fr)">
            {/* ***************************************   TOP  TIME AND DATE  **************************** */}
            <Flex
              height="270px"
              flexDirection="column"
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              mr="10px"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              justifyContent="space-between"
            >
              <Box>
                {suborder?.suborder_name ? (
                  <Heading
                    as="h4"
                    size="sm"
                    textAlign="left"
                    p="4"
                    bgColor="#f6f6f6"
                  >
                    Time And Date
                  </Heading>
                ) : (
                  <Heading
                    as="h4"
                    size="sm"
                    textAlign="left"
                    p="4"
                    bgColor="#f6f6f6"
                  >
                    Edit Time And Date
                  </Heading>
                )}
                <Flex direction="column" gap="10px" p="4" textAlign="left">
                  <Flex>Delivery Date : {shipping_date}</Flex>
                  <Flex>Delivery Time : {shipping_time}</Flex>
                  <Flex mb="15px">Delivery Mode : {shipping_method}</Flex>
                </Flex>
              </Box>
              <Box p="4">
                {" "}
                {!suborder?.suborder_name && (
                  <Text
                    width="max-content"
                    color="blue"
                    _hover={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => openCityAndDateModal(suborder)}
                  >
                    Edit Time
                  </Text>
                )}
              </Box>
            </Flex>
            {/* ***************************************   TOP EDITING ADDRESS  **************************** */}
            <Flex
              height="270px"
              flexDirection="column"
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              mr="10px"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              justifyContent="space-between"
            >
              <Box>
                {suborder?.suborder_name ? (
                  <Heading
                    as="h4"
                    p="4"
                    size="sm"
                    textAlign="left"
                    bgColor="#f6f6f6"
                  >
                    Address
                  </Heading>
                ) : (
                  <Heading
                    as="h4"
                    p="4"
                    size="sm"
                    textAlign="left"
                    bgColor="#f6f6f6"
                  >
                    Edit Address
                  </Heading>
                )}

                <Flex direction="column" gap="10px" p="4">
                  <Flex>City : {suborder?.shipping_address?.city}</Flex>
                  <Flex textAlign="left">
                    Address :{suborder?.shipping_address?.first_name},{" "}
                    {suborder?.shipping_address?.last_name},{" "}
                    {suborder?.shipping_address?.address1},{" "}
                    {suborder?.shipping_address?.address2},
                  </Flex>
                  <Flex>
                    Phone Number : {suborder?.shipping_address?.phone}
                  </Flex>
                </Flex>
              </Box>
              <Box p="4">
                {!suborder?.suborder_name && (
                  <Text
                    width="max-content"
                    color="blue"
                    _hover={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => openEditPropertyAddressModal(suborder_data)}
                  >
                    Edit Address
                  </Text>
                )}
              </Box>
            </Flex>
            {/* ***************************************   TOP EDITING  SENDER **************************** */}
            <Flex
              height="270px"
              flexDirection="column"
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              mr="5px"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              justifyContent="space-between"
            >
              <Box>
                {suborder?.suborder_name ? (
                  <>
                    <Heading
                      as="h4"
                      p="4"
                      size="sm"
                      textAlign="left"
                      bgColor="#f6f6f6"
                    >
                      Sender Detail
                    </Heading>
                    <Text textAlign="left" p="4">
                      Sender Name : {suborder.sender_name}
                    </Text>
                  </>
                ) : (
                  <Flex flexDir="column">
                    <Heading
                      as="h4"
                      size="sm"
                      p="15px"
                      mb="10px"
                      textAlign="left"
                      bgColor="#f6f6f6"
                    >
                      Edit Sender Detail
                    </Heading>
                    <Text textAlign="left" p="15px">
                      Sender Name :{sender_name}
                    </Text>
                    {edit_sender_detail && (
                      <Box mb="10px" width="200px" p="15px">
                        <Input
                          placeholder="Enter Sender Detail"
                          onChange={(e) => {
                            setSenderDetail(e.target.value);
                          }}
                        />
                      </Box>
                    )}
                    <Flex gap="20px" p="15px">
                      {edit_sender_detail && (
                        <Text
                          width="max-content"
                          color="red"
                          _hover={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => set_edit_sender_detail(false)}
                        >
                          Cancel Edit
                        </Text>
                      )}
                      {edit_sender_detail && (
                        <Text
                          width="max-content"
                          color="green"
                          _hover={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          onClick={HandleSaveSenderDetail}
                        >
                          {senderLoader ? "...saving" : "Save Edit"}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                )}
              </Box>
              <Box p="4">
                {!edit_sender_detail && !suborder?.suborder_id && (
                  <Text
                    width="max-content"
                    color="blue"
                    _hover={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => set_edit_sender_detail(true)}
                  >
                    Edit Detail
                  </Text>
                )}
              </Box>
            </Flex>
          </Grid>
          {/* ***************************************   TOP EDITING MESSEGE ON CARD **************************** */}
          <Grid templateColumns="66% 34%" gap="10px">
            <Flex
              flexDirection="column"
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              justifyContent="space-between"
            >
              <ForGreetingsMessage />
            </Flex>
            {/* ***************************************   OCCASION **************************** */}
            <Box
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              width="96%"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            >
              <Heading
                as="h4"
                size="sm"
                textAlign="left"
                p="4"
                bgColor="#f6f6f6"
              >
                Occasion
              </Heading>
              <Box>
                <Text mt="35px">Not available</Text>
              </Box>
            </Box>
          </Grid>
          {/* ***************************************   TOP EDITING SPECIAL **************************** */}
          <Flex>
            <Box
              backgroundColor="white"
              borderRadius="7px"
              color="black"
              mt="20px"
              mb="15px"
              width="100%"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            >
              <Heading
                as="h4"
                size="sm"
                textAlign="left"
                p="4"
                bgColor="#f6f6f6"
              >
                {suborder?.suborder_id
                  ? "Special Instructions"
                  : "Edit Special Instructions"}
              </Heading>
              <Box p="20px" textAlign="left">
                <SpecialInstructions />
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>

      {suborder?.line_items &&
        ForSelectingWhichIsSuborder(
          suborder?.line_items?.filter((p) => p.fulfillable_quantity !== 0)
        )?.map((order, i) => (
          <Box
            backgroundColor="white"
            borderRadius="7px"
            color="black"
            mt="30px"
            mb="15px"
            width="100%"
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          >
            {console.log(order,"order Sub-Order No.")}
            {/* **************************  SUBORDER NUMBER ********************************* */}
            <Flex
              justifyContent="space-between"
              backgroundColor="#f6f6f6"
              p="15px"
            >
              <Flex>
                {suborder.suborder_id ? (
                  <Text>
                    {" "}
                    <b>Sub-Order No.</b> : <b>{suborder?.suborder_name}</b>
                  </Text>
                ) : (
                  <Text>
                    <b>Sub-Order No.</b> :{" "}
                    <b>{suborder?.name + "_" + (i + 1)}</b>
                  </Text>
                )}
              </Flex>
            </Flex>

            <Box borderRadius="7px" p="4">
              <Box key={i}>
                {order?.map((item, index) => {
                  if (!item.variant_id) {
                    // **************************   CONDITION FOR CUSTOM ITEM  ******************************
                    return (
                      <Box key={index}>
                        {item.fulfillable_quantity === 0 &&
                        item.fulfillment_status === null ? null : (
                          <Table color="black" width="100%">
                            <Tbody>
                              <Tr>
                                <Td>
                                  <Flex flexDirection="column" gap="10px">
                                    <Text
                                      fontSize="20px"
                                      fontWeight="bold"
                                      width="200%"
                                    >
                                      {item.name}
                                    </Text>
                                    <Text fontWeight="bold" color="gray.700">
                                      Custom Item
                                    </Text>
                                  </Flex>
                                </Td>

                                <Td>
                                  <Flex flexDirection="column" gap="20px">
                                    <Flex gap="5px">
                                      <Text>Quantity</Text>
                                      <Text>:</Text>
                                      <Text>
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Td>

                                <Td>
                                  <Flex gap="5px">
                                    <Text>
                                      {
                                        item?.price_set?.shop_money
                                          ?.currency_code
                                      }
                                    </Text>
                                    <Text>
                                      {item?.price_set?.shop_money?.amount}
                                    </Text>
                                    <Text>x </Text>
                                    <Text>
                                      {item?.fulfillment_status === "fulfilled"
                                        ? item.quantity
                                        : item?.fulfillable_quantity}
                                    </Text>
                                  </Flex>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        )}
                      </Box>
                    );
                  } else {
                    return (
                      <div key={index}>
                        {/* *************************     MODAL FOR PP AND WP   ************************************* */}
                        <ModalForEditPPandWp
                          isOpen={indexofppandwp === index}
                          onClose={onClosemodalforppwp}
                          priceforpp={priceforpp}
                          setPricforpp={setPricforpp}
                          onClickFn={editWebsiteandPurchasePrice}
                        />
                        <>
                          {!item.properties.some(
                            (obj) => obj.name === "_parent_id"
                          ) ? (
                            <Box key={index}>
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex
                                  color="black"
                                  width="100%"
                                  justifyContent="space-between"
                                >
                                  <Flex>
                                    <Image
                                      src={
                                        item?.properties.find(
                                          (p) =>
                                            p.name.trim() === "_product_image"
                                        )?.value
                                      }
                                      height="200px"
                                      width="200px"
                                    />
                                    <Flex
                                      ml="20px"
                                      width="45%"
                                      flexDirection="column"
                                      gap="10px"
                                      textAlign="left"
                                    >
                                      <Text
                                        fontSize="20px"
                                        fontWeight="bold"
                                        width="200%"
                                      >
                                        {item.name}
                                      </Text>

                                      <Flex gap="100px">
                                        <Text
                                          color="gray.600"
                                          fontWeight="semibold"
                                        >
                                          sku : {item?.sku}
                                        </Text>
                                      </Flex>
                                      <Text color="gray.600" fontWeight="bold">
                                        Status :{" "}
                                        {suborder?.status
                                          ? capatilizedWord(suborder?.status)
                                          : capatilizedWord(
                                              item?.suborder_status
                                            )}
                                      </Text>

                                      {suborder.remark_notes && index == 0 && (
                                        <Text
                                          color="red"
                                          mt="-10px"
                                          fontWeight="bold"
                                        >
                                          Remark :{suborder.remark_notes}
                                        </Text>
                                      )}
                                      <Flex
                                        gap="5px"
                                        width="200%"
                                        alignItems="center"
                                      >
                                        <b>Allocated to</b>
                                        <Text>:</Text>
                                        <>
                                          {suborder?.suborder_id ? (
                                            <>
                                              <Text
                                                _hover={{
                                                  color: "blue",
                                                  textDecoration: "underline",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  navigate(
                                                    `/admin/vendor/${suborder?.allocated_to?.vendor_id}`
                                                  )
                                                }
                                              >
                                                {capatilizedWord(
                                                  suborder?.allocated_to
                                                    ?.vendor_name
                                                )}
                                              </Text>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              {item?.allocated_to
                                                ?.vendor_name ? (
                                                <Text
                                                  _hover={{
                                                    color: "blue",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    navigate(
                                                      `/admin/vendor/${item?.allocated_to?.vendor_id}`
                                                    )
                                                  }
                                                >
                                                  {capatilizedWord(
                                                    item?.allocated_to
                                                      ?.vendor_name
                                                  )}
                                                </Text>
                                              ) : (
                                                <>
                                                  {item?.suborder_status ===
                                                    "rejected" ||
                                                  item?.suborder_status ===
                                                    "conflicted" ? (
                                                    <Button
                                                      ml="5px"
                                                      fontWeight="semibold"
                                                      onClick={() => {
                                                        openAllocatetovendorModal(
                                                          item.related_suborder
                                                        );
                                                      }}
                                                      fontSize={{
                                                        base: "12px",
                                                        md: "14px",
                                                        lg: "14px",
                                                      }}
                                                    >
                                                      Allocate to Vendor
                                                    </Button>
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      </Flex>
                                      <Flex gap="20px">
                                        <Flex gap="5px">
                                          <b>Quantity</b>
                                          <Text>:</Text>
                                          <Text fontWeight="bold" color="red">
                                            {item?.fulfillment_status ===
                                            "fulfilled"
                                              ? item.quantity
                                              : item?.fulfillable_quantity}
                                          </Text>
                                        </Flex>
                                        <Flex gap="5px">
                                          <Text>
                                            {
                                              item?.price_set?.shop_money
                                                ?.currency_code
                                            }
                                          </Text>
                                          <Text>
                                            {
                                              item?.price_set?.shop_money
                                                ?.amount
                                            }
                                          </Text>
                                          <Text>x </Text>
                                          <Text>
                                            {item?.fulfillment_status ===
                                            "fulfilled"
                                              ? item.quantity
                                              : item?.fulfillable_quantity}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                      {/* LOGIC FOR PP AND WP */}
                                      {suborder?.suborder_id ? (
                                        <Flex gap="5px" flexDir="column">
                                          <Flex gap="5px">
                                            <b>PP</b>
                                            <Text>:</Text>
                                            <Text fontWeight="bold" color="red">
                                              {forSearchingWpAndPP(
                                                suborder,
                                                item,
                                                "pp"
                                              )}
                                            </Text>
                                          </Flex>
                                          <Flex gap="5px">
                                            <b>WP</b>
                                            <Text>:</Text>
                                            <Text fontWeight="bold" color="red">
                                              {forSearchingWpAndPP(
                                                suborder,
                                                item,
                                                "wp"
                                              )}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      ) : (
                                        // FOR ORDER PAGE
                                        <Flex gap="5px" flexDir="column">
                                          <Flex gap="5px">
                                            <b>PP</b>
                                            <Text>:</Text>
                                            <Text fontWeight="bold" color="red">
                                              {forSearchingWpAndPP(
                                                suborder,
                                                item,
                                                "pp"
                                              )}
                                            </Text>
                                            <Text
                                              ml="20px"
                                              color="blue"
                                              _hover={{
                                                cursor: "pointer",
                                                textDecoration: "uderline",
                                              }}
                                              onClick={() =>
                                                onOpenmodalforppwp(
                                                  index,
                                                  forSearchingWpAndPPForEditing(
                                                    suborder,
                                                    item
                                                  ),
                                                  "pp"
                                                )
                                              }
                                            >
                                              {" "}
                                              Edit{" "}
                                            </Text>
                                          </Flex>
                                          <Flex gap="5px">
                                            <b>WP</b>
                                            <Text>:</Text>
                                            <Text fontWeight="bold" color="red">
                                              {forSearchingWpAndPP(
                                                suborder,
                                                item,
                                                "wp"
                                              )}
                                            </Text>
                                            <Text
                                              ml="20px"
                                              color="blue"
                                              _hover={{
                                                cursor: "pointer",
                                                textDecoration: "uderline",
                                              }}
                                              onClick={() =>
                                                onOpenmodalforppwp(
                                                  index,
                                                  forSearchingWpAndPPForEditing(
                                                    suborder,
                                                    item
                                                  ),
                                                  "wp"
                                                )
                                              }
                                            >
                                              {" "}
                                              Edit{" "}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                      )}
                                    </Flex>
                                  </Flex>
                                  {/* GO TO SUBORDER BUTTON  */}
                                  {!suborder?.suborder_id && (
                                    <Button
                                      width="fit-content"
                                      onClick={() => {
                                        if (suborder?.status === null) {
                                          return;
                                        }
                                        {
                                          suborder.line_items[0]
                                            .related_suborder &&
                                            navigate(
                                              `/admin/order/suborder/${item.suborder_status}/${item.related_suborder}`
                                            );
                                        }
                                      }}
                                      colorScheme="teal"
                                      variant="outline"
                                      border="1px solid teal"
                                      fontSize={{
                                        base: "12px",
                                        md: "16px",
                                        lg: "16px",
                                      }}
                                    >
                                      Go to Suborder
                                    </Button>
                                  )}
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            // *******************************   FOR ADDONS  ***********************************
                            // <Box key={index} pl="150px">
                            <Box key={index}>
                              <Divider mt="10px" mb="10px" />
                              {item.fulfillable_quantity === 0 &&
                              item.fulfillment_status === null ? null : (
                                <Flex color="black" width="100%" gap="10px">
                                  <Image
                                    src={
                                      item?.properties.find(
                                        (p) =>
                                          p.name.trim() === "_product_image"
                                      )?.value
                                    }
                                    height="200px"
                                    width="200px"
                                  />
                                  <Flex
                                    flexDirection="column"
                                    gap="10px"
                                    textAlign="left"
                                    pl="10px"
                                  >
                                    <Text
                                      ml="10px"
                                      fontSize="20px"
                                      fontWeight="bold"
                                      width="200%"
                                    >
                                      {item.name}
                                    </Text>
                                    <Text
                                      color="gray.600"
                                      ml="10px"
                                      fontWeight="semibold"
                                    >
                                      sku : {item?.sku}
                                    </Text>
                                    <Flex gap="5px" ml="10px">
                                      <Text>Quantity</Text>
                                      <Text>:</Text>
                                      <Text fontWeight="bold" color="red">
                                        {item?.fulfillment_status ===
                                        "fulfilled"
                                          ? item.quantity
                                          : item?.fulfillable_quantity}
                                      </Text>
                                      <Flex gap="5px" ml="10px">
                                        <Text>
                                          {
                                            item?.price_set?.shop_money
                                              ?.currency_code
                                          }
                                        </Text>
                                        <Text>
                                          {item?.price_set?.shop_money?.amount}
                                        </Text>
                                        <Text>x </Text>
                                        <Text>
                                          {item?.fulfillment_status ===
                                          "fulfilled"
                                            ? item.quantity
                                            : item?.fulfillable_quantity}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                    {/* FOR PP WP FOR ADDONS */}
                                    {suborder?.suborder_id ? (
                                      <Flex
                                        gap="5px"
                                        flexDir="column"
                                        ml="10px"
                                      >
                                        <Flex gap="5px">
                                          <b>PP</b>
                                          <Text>:</Text>
                                          <Text fontWeight="bold" color="red">
                                            {forSearchingWpAndPP(
                                              suborder,
                                              item,
                                              "pp"
                                            )}
                                          </Text>
                                        </Flex>
                                        <Flex gap="5px">
                                          <b>WP</b>
                                          <Text>:</Text>
                                          <Text fontWeight="bold" color="red">
                                            {forSearchingWpAndPP(
                                              suborder,
                                              item,
                                              "wp"
                                            )}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                    ) : (
                                      // FOR ORDER PAGE
                                      <Flex
                                        gap="5px"
                                        flexDir="column"
                                        ml="10px"
                                      >
                                        <Flex gap="5px">
                                          <b>PP</b>
                                          <Text>:</Text>
                                          <Text fontWeight="bold" color="red">
                                            {forSearchingWpAndPP(
                                              suborder,
                                              item,
                                              "pp"
                                            )}
                                          </Text>
                                          <Text
                                            ml="20px"
                                            color="blue"
                                            _hover={{
                                              cursor: "pointer",
                                              textDecoration: "uderline",
                                            }}
                                            onClick={() =>
                                              onOpenmodalforppwp(
                                                index,
                                                forSearchingWpAndPPForEditing(
                                                  suborder,
                                                  item
                                                ),
                                                "pp"
                                              )
                                            }
                                          >
                                            {" "}
                                            Edit{" "}
                                          </Text>
                                        </Flex>
                                        <Flex gap="5px">
                                          <b>WP</b>
                                          <Text>:</Text>
                                          <Text fontWeight="bold" color="red">
                                            {forSearchingWpAndPP(
                                              suborder,
                                              item,
                                              "wp"
                                            )}
                                          </Text>
                                          <Text
                                            ml="20px"
                                            color="blue"
                                            _hover={{
                                              cursor: "pointer",
                                              textDecoration: "uderline",
                                            }}
                                            onClick={() =>
                                              onOpenmodalforppwp(
                                                index,
                                                forSearchingWpAndPPForEditing(
                                                  suborder,
                                                  item
                                                ),
                                                "wp"
                                              )
                                            }
                                          >
                                            {" "}
                                            Edit{" "}
                                          </Text>
                                        </Flex>
                                      </Flex>
                                    )}
                                  </Flex>
                                </Flex>
                              )}
                              <Divider mt="10px" mb="10px" />
                            </Box>
                          )}
                        </>
                      </div>
                    );
                  }
                })}
                <Box>
                  {/* ***************  MAIN BOX ********************** */}
                  <Flex>
                    {/*  ***************  personalized image ********************* */}
                    <Flex justifyContent={"space-evenly"} flex="3">
                      <Box
                        backgroundColor="white"
                        borderRadius="7px"
                        color="black"
                        mt="20px"
                        minHeight="200px"
                        mr="10px"
                        width="96%"
                        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                      >
                        <Heading
                          as="h4"
                          size="sm"
                          textAlign="left"
                          p="4"
                          bgColor="#f6f6f6"
                        >
                          Personalized Image
                        </Heading>

                        {JSON.parse(
                          imagetoshow(finditem(order)?.properties)
                        )?.toString() ? (
                          checkmultipleimagornot(finditem(order)?.properties)
                            .length == 1 ? (
                            checkmultipleimagornot(
                              finditem(order)?.properties
                            ).map((img) => (
                              <img src={img} alt="fnp" paddingRight="8px" />
                            ))
                          ) : (
                            <Grid templateColumns="repeat(2,1fr)" gap="5px">
                              {checkmultipleimagornot(
                                finditem(order)?.properties
                              ).map((img) => (
                                <Image
                                  src={img}
                                  alt="fnp"
                                  paddingRight="8px"
                                  height="300px"
                                  width="300px"
                                />
                              ))}
                            </Grid>
                          )
                        ) : (
                          <Text mt="35px">No Personalized Image</Text>
                        )}
                      </Box>
                    </Flex>
                    <Grid
                      justifyContent={"space-evenly"}
                      templateColumns="repeat(1,1fr)"
                      flex="3"
                    >
                      {/*  ***************  message on cake ********************* */}
                      <Box
                        minH="200px"
                        backgroundColor="white"
                        borderRadius="7px"
                        color="black"
                        mt="20px"
                        width="96%"
                        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                      >
                        <Heading
                          as="h4"
                          size="sm"
                          textAlign="left"
                          p="4"
                          bgColor="#f6f6f6"
                        >
                          Message on Cake
                        </Heading>
                        <Flex flexDir="column" justifyContent="space-between">
                          <Box p="20px">
                            {finditem(order)?.properties.find(
                              (k) => k.name === "old_message"
                            )?.value && (
                              <Text as="del" color="gray.600">
                                <b>Old Message: </b>
                                <span color="#222222">
                                  {
                                    finditem(order).properties.find(
                                      (k) => k.name === "old_message"
                                    )?.value
                                  }
                                </span>
                              </Text>
                            )}
                            {finditem(order)?.properties.find(
                              (k) => k.name === "_message"
                            ) && (
                              <Flex gap="10px">
                                <b>Message: </b>
                                <Text fontWeight="semibold" textAlign="left">
                                  {
                                    finditem(order)?.properties.find(
                                      (k) => k.name === "_message"
                                    )?.value
                                  }
                                </Text>
                              </Flex>
                            )}
                          </Box>
                          <Box p="4">
                            {!suborder?.suborder_id && (
                              <Text
                                width="max-content"
                                color="blue"
                                _hover={{
                                  textDecorationLine: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openCakeMessageModal(finditem(order)?.id)
                                }
                              >
                                Edit Message
                              </Text>
                            )}
                          </Box>
                        </Flex>
                      </Box>
                      {/*  ***************  personalized text ********************* */}
                      <Box
                        minH="200px"
                        backgroundColor="white"
                        borderRadius="7px"
                        color="black"
                        mt="20px"
                        width="96%"
                        boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                      >
                        <Heading
                          as="h4"
                          size="sm"
                          textAlign="left"
                          p="4"
                          bgColor="#f6f6f6"
                        >
                          Personalized Text
                        </Heading>
                        <Box>
                          <Text mt="35px">Not available</Text>
                        </Box>
                      </Box>
                    </Grid>
                    {/*  ***************  personalized image ********************* */}
                    <Box
                      flex="3"
                      minH="200px"
                      backgroundColor="white"
                      borderRadius="7px"
                      color="black"
                      mt="20px"
                      width="96%"
                      boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                    >
                      <Heading
                        as="h4"
                        size="sm"
                        textAlign="left"
                        p="4"
                        bgColor="#f6f6f6"
                      >
                        Image Captured by Delivery Boy
                      </Heading>
                      <Box>
                        <Text mt="35px">Not available</Text>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}

      <Box bgColor="#F5F5F5">
        {/* ********************************* FULLFILLED BOX ************************************************* */}

        {showBoxOrNot(suborder?.line_items) ? (
          <Box
            boxShadow="rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
            backgroundColor="white"
            color="black"
            mt="30px"
            borderRadius="7px"
            p="3"
            mb="20px"
            textAlign="left"
          >
            <br />
            <Flex gap="15px" mb="10px">
              <Box
                height="fit-content"
                border="5px solid #A1EDD0"
                borderRadius="50%"
              >
                <BiCheckCircle fontSize="20px" color="#A1EDE9" />
              </Box>
              <Heading
                as="h5"
                size="md"
                fontFamily="NexaText-Trial-Bold"
                mb="15px"
                mt="5px"
              >
                fulfilled
              </Heading>
            </Flex>
            {ForSelectingWhichIsSuborder(
              suborder?.line_items.filter(
                (k) => k.fulfillment_status === "fulfilled"
              )
            )?.map((order, i) => (
              <div key={i}>
                <Box
                  mt="10px"
                  width="100%"
                  height="2px"
                  bg="black"
                  display={i == 0 ? "none" : "block"}
                ></Box>
                {order.map((item, i) => (
                  <Box key={i} mb="30px">
                    {!item.properties.some(
                      (obj) => obj?.name === "_parent_id"
                    ) ? (
                      <Flex justifyContent="space-between" mb="10px">
                        <Flex gap="20px">
                          <Image
                            src={
                              item?.properties?.find(
                                (p) => p?.name?.trim() === "_product_image"
                              )?.value
                            }
                            height="80px"
                            width="80px"
                          />
                          <Flex direction="column" fontSize="14px" mt="-5px">
                            <Text fontWeight="semibold">{item.title}</Text>
                            <Text color="gray.500">SKU: {item?.sku}</Text>
                            <Text>
                              {item.quantity} X QAR {item?.price}
                            </Text>
                          </Flex>
                        </Flex>
                        <Text>QAR {+item?.quantity * +item?.price}</Text>
                        <Text>
                          {item?.deliveryDateTime &&
                            moment(item?.deliveryDateTime)
                              .tz("Asia/Qatar")
                              .format("YYYY-MMMM-DD [at] HH:mm")}
                        </Text>
                      </Flex>
                    ) : (
                      <Box>
                        {/* <Divider /> */}
                        <Flex
                          justifyContent="space-between"
                          mb="10px"
                          ml="100px"
                          mt="20px"
                        >
                          <Flex gap="20px">
                            <Image
                              src={
                                item?.properties.find(
                                  (p) => p.name === "_product_image"
                                )?.value
                              }
                              height="80px"
                              width="80px"
                            />
                            <Flex direction="column" fontSize="14px" mt="-5px">
                              <Text fontWeight="semibold">{item.title}</Text>
                              <Text color="gray.500">SKU: {item?.sku}</Text>
                              <Text>
                                {item.quantity} X QAR {item?.price}
                              </Text>
                            </Flex>
                          </Flex>
                          <Text>QAR {+item?.quantity * +item?.price}</Text>
                        </Flex>
                      </Box>
                    )}
                  </Box>
                ))}
              </div>
            ))}
            <Divider />
            <Text mt="10px" color="gray.500" fontWeight="semibold">
              Fulfilled Orders Can't be Changed
            </Text>
          </Box>
        ) : null}
        {!suborder?.suborder_id && (
          <>
            {suborder?.financial_status === "paid" && <PaidComp />}
            {suborder?.financial_status !== "paid" && <Partiallypaid />}
          </>
        )}
        {suborder?.suborder_id && <TimelineComp suborder={suborder} />}
      </Box>
    </Box>
  );
};

export default TopBar;
