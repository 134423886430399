import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Divider,
  Td,
  Flex,
  Image,
  Text,
  Spinner,
} from "@chakra-ui/react";
import COD from "../images/COD.png";
import urgentLogo from "../images/urgent_logo.jpg";
import "./print.css";
import axios from "axios";
import fnp_logo from "../images/fnp_logo.jpg";

const SubOrderChallan = ({ data, index }) => {
  const [lineItemforShipping, setLinItemForShipping] = useState([]);
  const parentItem = data?.line_items?.find(
    (item) => item.id?.toString() === data?.line_item_id
  );
  let [urgentShippingMethods, SeturgentShippingMethods] = useState([]);

  console.log(urgentShippingMethods, "datadata");
  const [priceloader, setPriceLoader] = useState(false);

  const [isUrgent, setIsUrgent] = useState(false);
  async function forGettingOrderLineItem() {
    if (priceloader) {
      return;
    }
    setPriceLoader(true);
    let headers;
    if (sessionStorage.getItem("admintoken")) {
      headers = {
        Authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("admintoken")
        )}`,
      };
      try {
        let dataforchallan = await axios.get(
          `${process.env.REACT_APP_API_URL}/order/read/${data?.parent_id}`,
          { headers }
        );
        // console.log(data.data, "challandata");
        let line_item = dataforchallan?.data.line_items.filter(
          (k) => k.fulfillable_quantity !== 0 && !k.variant_id
        );
        setLinItemForShipping(line_item);
        // console.log(line_item, "item in challan");
        setPriceLoader(false);
      } catch (err) {
        console.log(err, "error inchallan");
        setPriceLoader(false);
      }
    } else if (sessionStorage.getItem("partnertoken")) {
      headers = {
        Authorization: `Bearer ${JSON.parse(
          sessionStorage.getItem("partnertoken")
        )}`,
      };
      try {
        let dataforchallan = await axios.get(
          `${process.env.REACT_APP_API_URL}/order/partnerread/${data?.parent_id}`,
          { headers }
        );
        // console.log(data.data, "challandata");
        let line_item = dataforchallan?.data.line_items.filter(
          (k) => k.fulfillable_quantity !== 0 && !k.variant_id
        );
        setLinItemForShipping(line_item);
        // console.log(line_item, "item in challan");
        setPriceLoader(false);
      } catch (err) {
        console.log(err, "error inchallan");
        setPriceLoader(false);
      }
    }
  }
  const codpicture =
    data?.financial_status !== "paid" &&
    data?.payment_gateway_names.includes("Cash on Delivery (COD)");
  data?.payment_gateway_names.includes("Cash on Delivery (COD)");

  function extractObjdata(obj) {
    let valuesArray = [];
    let newobj = {
      first_name: obj.first_name + " " + obj.last_name,
      address1: obj.address1,
      address2: obj.address2,
      city: obj.city,
      country: obj.country,
      phone: obj.phone,
    };

    for (let key in newobj) {
      if (newobj.hasOwnProperty(key)) {
        valuesArray.push(newobj[key]);
      }
    }

    return valuesArray;
  }
  const address =
    typeof data.shipping_address === "string"
      ? data?.shipping_address?.split(",").map((item, i) => item)
      : typeof data.shipping_address === "object"
      ? extractObjdata(data.shipping_address)
      : null;
  const orderdate = new Date(
    data?.created_at?.split("T")[0]
  )?.toLocaleDateString("en-GB");
  let delivery_dd =
    (data?.to_be_shipped_on &&
      [...data?.to_be_shipped_on?.trim()?.split("-")]?.reverse()) ||
    null;
  const deliverydate = delivery_dd?.join("/");
  const timeSlot = data?.time_slot;
  const sendername = data?.sender_name || "";
  const message = parentItem?.properties?.find(
    (prop) => prop.name.trim().toLowerCase() === "_message"
  )?.value;
  const shipping = data?.shipping_method;
  let greetings_message = data?.gift_message;
  let dataForTable = data?.line_items
    .filter((k) => k.fulfillable_quantity !== 0)
    .map((item) => {
      let prop = null;
      let value = item?.properties.find(
        (k) => k.name.toString().trim() === "_product_description"
      )?.value;
      if (value !== "null") {
        prop = item?.properties.find(
          (k) => k.name.toString().trim() === "_product_description"
        )?.value;
      }
      return {
        quantity: item.quantity,
        message: item?.properties.filter(
          (property) => property.name.trim() === "_message"
        )[0]?.value,
        image: item?.properties.filter(
          (k) => k.name.toString().trim() === "_product_image"
        )[0]?.value,
        name: item.name,
        description: prop,
        image_prod: item?.properties.filter(
          (property) => property.name.trim() === "_imagesUrl"
        )[0]?.value,
      };
    });
  // console.log(dataForTable, "datatbale");

  const Totalprice = calculatePrice();

  function calculatePrice() {
    let arr = [];
    const shipping = Number(data?.shipping_charges) || 0;

    const totalnum = data.line_items.reduce((acc, item) => {
      if (item.fulfillable_quantity > 0 && item.variant_id) {
        const quan = Number(item.quantity);
        const price = Number(item.price);
        const pricetotal = quan * price;
        acc += pricetotal;
        item.discount_allocations.forEach((prop) => {
          acc -= Number(prop.amount);
        });
      }
      return acc;
    }, 0);

    let totalprice = totalnum + shipping;
    let check = checkItsFirstOrNot(data.suborder_name);
    if (check) {
      let line_itemPrice;
      line_itemPrice = lineItemforShipping.reduce((acc, item) => {
        const quan = Number(item.quantity);
        const price = Number(item.price);
        const pricetotal = quan * price;
        acc += pricetotal;
        return acc;
      }, 0);
      totalprice = totalprice + line_itemPrice;
    }
    return totalprice.toFixed(2);
  }

  function checkItsFirstOrNot(name) {
    let k = name.split("_");
    let m = k.slice(-1);
    if (m[0] === "1") {
      return true;
    } else {
      return false;
    }
  }
  checkItsFirstOrNot("#1208_1");

  useEffect(() => {
    calculatePrice();
    forGettingOrderLineItem();
    getShippingMethodsOfUrgentStamp();
  }, [data]);

  // console.log(data, "data");

  const checkUrgentStatus = () => {
    const shipping_method = data.shipping_method;
    const isUrgentTime = urgentShippingMethods.includes(shipping_method);
    console.log("present or not", isUrgentTime);

    if (isUrgentTime) {
      setIsUrgent(true);
    } else {
      setIsUrgent(false);
    }
  };

  useEffect(() => {
    checkUrgentStatus();
  }, [timeSlot, urgentShippingMethods]);

  let [urgentLoader, setUrgentLoader] = useState(false);
  function getShippingMethodsOfUrgentStamp() {
    if (urgentLoader) return;
    setUrgentLoader(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/shipping/read/urgent_stamp`,
    })
      .then((r) => {
        console.log(r.data.shipping_methods, "result by shipping");
        SeturgentShippingMethods(r.data.shipping_methods);
        setUrgentLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setUrgentLoader(false);
      });
  }

  return (
    <>
      {priceloader || urgentLoader ? (
        <Box display={index === 0 ? "block" : "none"}>
          <Spinner />
        </Box>
      ) : (
        <Box
          width="90%"
          margin="auto"
          className="print-content"
          fontSize="12px"
        >
          {/* ************************************   FOR EMPTY SPACES ************************************** */}
          <Flex
            height="32vh"
            display="inline-block"
            width="100%"
            justifyContent="space-between"
          >
            <Box width="50%" height="30px" marginLeft="50%" mt="40px">
              {data?.message_receiver && (
                <Flex
                  mb="10px"
                  pl="100px"
                  gap="10px"
                  fontSize="16px"
                  fontWeight="semibold"
                >
                  <Flex>To : </Flex>
                  <Flex fontWeight="semibold">{data?.message_receiver}</Flex>
                </Flex>
              )}
              {greetings_message && (
                <Flex
                  mb="10px"
                  width="100%"
                  float="right"
                  textAlign="left"
                  flexWrap="wrap"
                  fontWeight="semibold"
                  fontSize="16px"
                  pl="100px"
                  flexDirection="column"
                >
                  {greetings_message.split("\n").map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </Flex>
              )}
              {data?.message_sender && (
                <Flex
                  mt="10px"
                  fontSize="16px"
                  pl="100px"
                  gap="10px"
                  fontWeight="semibold"
                >
                  <Flex>From : </Flex>
                  <Flex> {data?.message_sender}</Flex>
                </Flex>
              )}
            </Box>
          </Flex>
          <Divider
            variant="dashed"
            colorScheme="black"
            borderColor="black"
            borderWidth="1.5px"
          />
          <Box>
            {/* ************************************   COD AND LOGO ************************************** */}
            <Flex justifyContent="space-between" p="0px 50px 0px 50px">
              <Flex fap="10px">
                <Box mr="10px">
                  <Image
                    src={fnp_logo}
                    alt="fnp"
                    height="70px"
                    width="150px"
                    margin="10px auto"
                  />
                </Box>
                {codpicture && (
                  <Image src={COD} height="60px" width="60px" margin="auto" />
                )}
                {isUrgent && (
                  <Image
                    src={urgentLogo}
                    height="45px"
                    width="45px"
                    margin="auto"
                    ml={"5px"}
                  />
                )}
              </Flex>
              <Box></Box>
            </Flex>
            <Divider
              colorScheme="black"
              borderColor="black"
              borderWidth="1px"
            />
            {/* *************************   FOR ORDER DETAIL *************************** */}
            <Flex p="10px 50px 10px 50px" justifyContent="space-between">
              <Box textAlign="left" width="60%" flexWrap="wrap">
                <Text fontWeight="bold">Deliver To :</Text>
                {typeof address === "object" ? (
                  <Flex flexDirection="column" flexWrap="wrap">
                    {address?.map((item, i) => (
                      <Text key={i} overflow="visible" maxW="100%">
                        {item}
                      </Text>
                    ))}
                  </Flex>
                ) : (
                  <Text>{address}</Text>
                )}
              </Box>
              <Box textAlign="left">
                <Flex gap="5px">
                  <Text fontWeight="bold">Order :</Text>
                  <Text fontSize="14px"> {data?.name}</Text>
                </Flex>
                {codpicture && (
                  <Flex gap="5px">
                    <Text fontWeight="bold" color="red">
                      Order Amount :
                    </Text>
                    <Text color="red">{Totalprice} </Text>
                  </Flex>
                )}
                <Flex gap="5px">
                  <Text fontWeight="bold">Order Date :</Text>
                  <Text>{orderdate}</Text>
                </Flex>
                <Flex gap="5px">
                  <Text fontWeight="bold">Delivery Date :</Text>
                  <Text>{deliverydate}</Text>
                </Flex>
                <Flex gap="5px">
                  <Text fontWeight="bold">Shipping </Text>
                  <Text>:</Text>
                  <Text>{shipping} </Text>
                </Flex>
                <Flex gap="5px">
                  <Text fontWeight="bold">Time : </Text>
                  <Text>{timeSlot}</Text>
                </Flex>
              </Box>
            </Flex>
            {/* *************************   FOR PRODUCT DETAIL *************************** */}
            <Box className="table">
              <Table variant="simple" mt="20px" className="table">
                <Thead
                  className="thead"
                  style={{
                    border: "2px solid black",
                    borderRadius: "5px",
                    padding: "10px",
                    textAlign: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Tr
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Th
                      style={{
                        border: "2px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      quantity
                    </Th>
                    <Th
                      style={{
                        border: "2px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Product Image
                    </Th>
                    <Th
                      style={{
                        border: "2px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Title
                    </Th>
                    <Th
                      style={{
                        border: "2px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Message
                    </Th>

                    <Th
                      style={{
                        border: "2px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      description
                    </Th>
                    <Th
                      style={{
                        border: "2px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      Uploaded Image
                    </Th>
                  </Tr>
                </Thead>
                {dataForTable.map((item, i) => (
                  <Tbody
                    key={i}
                    alignItems="center"
                    style={{
                      border: "2px solid black",
                      borderRadius: "5px",
                      padding: "10px",
                      textAlign: "center",
                    }}
                  >
                    <Tr>
                      <Td
                        width="5px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          padding: "5px",
                          textAlign: "center",
                          color: "red",
                          fontWeight: "semibold",
                          fontSize: "18px",
                        }}
                      >
                        {item?.quantity}
                      </Td>
                      <Td
                        width="10px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          padding: "10px",
                          textAlign: "center",
                          margin: "auto",
                          width: "100px", // Set the fixed width for the table cell
                        }}
                      >
                        <Image
                          src={item?.image}
                          height="80px"
                          width="auto"
                          maxWidth="100%"
                          margin="auto"
                        />
                      </Td>
                      <Td
                        width="150px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          padding: "10px 0px 10px 30px",
                        }}
                      >
                        {item?.name}
                      </Td>
                      <Td
                        width="70px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          padding: "10px 0px 10px 30px",
                        }}
                      >
                        {item.message?.replace(/"/g, "")}
                      </Td>

                      <Td
                        width="300px"
                        pl="20px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          textAlign="left"
                          pl="20px"
                          flex={5}
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></Box>
                      </Td>
                      <Td
                        width="70px"
                        whiteSpace="normal"
                        wordBreak="break-word"
                        style={{
                          border: "2px solid black",
                          borderRadius: "5px",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {item.image_prod && item.image_prod !== "[]" && (
                          <Image
                            src={JSON.parse(item.image_prod).toString()}
                            height="80px"
                            width="80px"
                            margin="auto"
                          />
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                ))}
              </Table>
            </Box>

            <Text
              fontWeight="semibold"
              fontSize="12px"
              mt="10px"
              textAlign="left"
            >
              Sender : {sendername}
            </Text>

            {data?.notes.trim() && (
              <Text
                fontWeight="semibold"
                fontSize="16px"
                mt="10px"
                textAlign="left"
                color="red"
              >
                Notes: {data?.notes}
              </Text>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SubOrderChallan;
