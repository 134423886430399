import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Checkbox,
  Divider,
  Box,
  Flex,
  FormLabel,
  FormControl,
  useToast,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { setToast } from "../../../Fucntions/Toastfunction";
import { useDispatch } from "react-redux";
import { getUpdatedOrderAfterAssignRejectedOrder } from "../../../redux/Admin/AppReducer/orders/action";

const ModaldorAllocatevendor = ({ isOpen, onClose ,relatedId}) => {
  console.log(relatedId,"iof")

  const toast=useToast()
  const dispatch=useDispatch()
  const [vendor, setVendor] = useState([]);
  const [assignvendor, setAssignVendor] = useState([]);
const navigate=useNavigate()
const location=useParams()
  function handleSelectVendor(selid) {

    let arr = [...assignvendor];
    if (assignvendor.includes(selid)) {
      arr = [];
    } else {
      arr = [];
      arr.push(selid);
    }
    setAssignVendor(arr);
  }
 const [loadervendor,setLoaderVendor]=useState(false)

 function getAvailableVendors() {
    if(!isOpen){
      return
    }
    if(loadervendor){
      return
    }
    setLoaderVendor(true)
    console.log("called")
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    axios({
      method: "get",
      withCredentials: false,
      url: `${process.env.REACT_APP_API_URL}/suborder/getAvailableVendors/${relatedId}`,
      headers: headers,
    })
      .then((r) => {
        setVendor(r.data);
        setLoaderVendor(false)
      })
      .catch((e) => {
        if(e.response.data.err==="Invalid token"){
          sessionStorage.removeItem('admintoken');
          navigate("/admin/login")
          return
        }
        console.log(e)
        setToast(toast,`${e.response.data.err}`,"","error")
        setLoaderVendor(false)
      });
  } 

  const [saveloader,setloader]=useState(false)
  function handleSaveAssignVendorHitApi(){
    if(saveloader){
      return
    }
    setloader(true)
    const headers = {
      Authorization: `Bearer ${JSON.parse(
        sessionStorage.getItem("admintoken")
      )}`,
    };
    let data={
      vendor_id:assignvendor[0]
    }

     axios({
        method:"PUT",
        url:`${process.env.REACT_APP_API_URL}/suborder/assign_vendor/${relatedId}`,
        data,
        headers
      }).then((r)=>{
        console.log(r,"rrrr")
        setloader(false)
       setToast(toast,"Vendor Assign Successfully","","success")
       onClose()
      dispatch(getUpdatedOrderAfterAssignRejectedOrder(r.data))
       setVendor([])
      })
.catch((err)=>{
  setloader(false)
  if(err.response.data.err==="Invalid token"){
    sessionStorage.removeItem('admintoken');
    navigate("/admin/login")
    return
  }
  setToast(toast,`${err.response.data.err}`,"","error")
})
   
  }
  useEffect(() => {
    getAvailableVendors();
  }, [isOpen]);

  return (
    <div>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        size="md"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Allocate Vendor</ModalHeader>
          <ModalCloseButton onClick={()=>setVendor([])} />
          <ModalBody pb={6}>
            <Divider />
            {loadervendor?
            <Spinner
            mt="30px"
             thickness='4px'
             speed='0.65s'
             emptyColor='gray.200'
             color='blue.500'
             size='xl'/>:<Box>
            {vendor.length===0?<Text color="red.400" fontSize='20px'
            fontWeight="semibold" mt="10px" mb="10px">No vendors Available</Text>:<Box mt="20px">
              {vendor.map((item) => (
                <FormControl>
                  <Flex key={item._id} gap="10px" mb="10px">
                    <Checkbox
                      onChange={() => handleSelectVendor(item.vendor_id)}
                      size="lg"
                      isChecked={assignvendor.includes(item.vendor_id)}
                    />
                    <FormLabel mt="5px">
                      {item.vendor_name} ({item.vendor_id})
                    </FormLabel>
                  </Flex>
                </FormControl>
              ))}
            </Box>}
            </Box>}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3}
            onClick={handleSaveAssignVendorHitApi}
         isDisabled={assignvendor.length===0}
            >
              {saveloader?<Spinner/>:"Save"}
            </Button>
            <Button onClick={()=>{
              setVendor([])
              onClose()}}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModaldorAllocatevendor;
